import * as React from 'react';
import { translate } from '../../../lang';
import { className, convertToCSSClassname } from '../../../util';

interface InterestInputProps {
  name: string;
  value: string;
  type?: string;
  change: (text: string) => void;
  error?: string;
  visible: boolean;
  cssClass: string;
  autoComplete?: string;
}

export function InterestInput(props: InterestInputProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.change(e.target.value);
  };

  return (
    <div className={className(props.cssClass, {'dso-visible' : props.visible})}>
      <div className='dso-text-input-error'>
        <span className={className('dso-text-input-error-text', { 'visible': !!props.error })}>
          {props.error === 'Mixed'
            ? translate('form.interest.error.mandatory')
            : translate('form.interest.error.email')}
        </span>
      </div>

      <input
        onChange={handleChange}
        type={props.type ?? 'text'}
        value={props.value}
        className={className('dso-text-input', { 'error': !!props.error })}
        autoComplete={props.autoComplete}
        placeholder={props.name} />
    </div>
  );
}

export function InterestInputMessage(props: InterestInputProps) {
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.change(e.target.value);
  };

  return (
    <div className={className(props.cssClass, {'dso-visible' : props.visible})}>
      <div className='dso-text-input-error'>
        <span className={className('dso-text-input-error-text', { 'visible': !!props.error })}>
          {props.error}
        </span>
      </div>

      <textarea
        onChange={handleChange}
        className={className('dso-text-input', { 'error': !!props.error })}
        autoComplete={props.autoComplete}
        placeholder={props.name}
        value={props.value}>
      </textarea>

    </div>
  );
}

interface DsoCheckboxProps {
  checked: boolean;
  cssClass: string;
  toggle: () => void;
  id: string;
}

export function DsoCheckbox(props: React.PropsWithChildren<DsoCheckboxProps>) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.toggle();
  };

  return (
    <label className={props.cssClass}>
      <input
        type='checkbox'
        id={props.id}
        checked={props.checked}
        onChange={handleChange}/>
      {props.children}
      <span className='checkmark'></span>
    </label>
  );
}

interface ActionButtonProps {
  label: string;
  click: () => void;
  positive?: boolean;
  icon?: string;
  inlineStyle?: boolean;
}

export function ActionButton(props: ActionButtonProps) {

  const cls = className(
    'dso-action-button',
    { 'dso-action-positive': props.positive },
    convertToCSSClassname(props.label));

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    props.click();
  };

  const icon = `fa fa-${props.icon} dso-icon`;

  return (
    <button className={className(cls, {inline: props.inlineStyle})} onClick={handleClick}>
      <i className={icon}></i>{props.label}
    </button>
  );
}