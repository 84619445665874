import * as React from 'react';
import { VehicleShare } from '../../model/core';
import { translate as trans } from '../../lang';
import { ActionButton } from './form/input';

const facebookAppId = 865039600738254;

interface ShareVehicleProps {
  link: string;
  sharing: VehicleShare;
}

interface ShareVehicleState {
  toggled: boolean;
  textCopied: boolean;
}

export class ShareVehicle extends React.Component<ShareVehicleProps, ShareVehicleState> {

  constructor(props: ShareVehicleProps) {
    super(props);

    this.state = {
      toggled: false,
      textCopied: false
    };
  }

  close(e: any) {
    if (e.target.getAttribute('itemid') === 'Close') {
      this.setState({ toggled: false });
    }
  }

  share(target: string, external: boolean) {
    if (external) {
      return window.open(
        target,
        '_blank'
      );
    }
    return window.location.href = target;
  }

  copy() {
    navigator.clipboard.writeText(this.props.link);
    this.setState({ textCopied: true });

    setTimeout(() => {
      this.setState({ toggled: false, textCopied: false });
    }, 1500);
  }

  render() {
    const { sharing } = this.props;

    return(
      <div className='dso-share-vehicle'>

        <ActionButton
          icon='share-alt'
          label={trans('share.main')}
          click={() => this.setState({ toggled: true })}
          inlineStyle={true} />

        {this.state.toggled && <>
          <div className='dso-share-prompt' onClick={(e) => this.close(e)} itemID={'Close'}>
            <div className='dso-share-content' onClick={(e) => this.close(e)} itemID={'Close'}>
              <div className='dso-share-centerer'>

                {sharing.facebook && <>
                  <div className='share facebook'
                    onClick={() => this.share(
                      'https://www.facebook.com/dialog/share?app_id=' + facebookAppId +
                      '&href=' + this.props.link,
                      true
                    )}>
                    <span><i className="fab fa-facebook-square"></i>{trans('share.facebook')}</span>
                    <i className="fas fa-long-arrow-alt-right right-icon"></i>
                  </div>
                </>}

                {sharing.messenger && <>
                  <div className='share messenger'
                    onClick={() => this.share(
                      'http://www.facebook.com/dialog/send?app_id=' + facebookAppId +
                      '&link=' + this.props.link +
                      '&redirect_uri=' + window.location.origin,
                      true
                    )}>
                    <span><i className="fab fa-facebook-messenger"></i>{trans('share.messenger')}</span>
                    <i className="fas fa-long-arrow-alt-right right-icon"></i>
                  </div>
                </>}

                {sharing.mail && <>
                  <div className='share mail'
                    onClick={() => this.share(
                      'mailto:någons@adress.se?subject=' + trans('share.mail.subject') + '&body=' + this.props.link,
                      false
                    )}>
                    <span><i className="fas fa-envelope-open-text"></i>{trans('share.mail')}</span>
                    <i className="fas fa-long-arrow-alt-right right-icon"></i>
                  </div>
                </>}

                {sharing.link && <>
                  <div className='share copy'
                    onClick={() => this.copy()}>
                    <span>
                      <i className="fas fa-link"></i>
                      {this.state.textCopied ? trans('share.copy.copied') : trans('share.copy')}
                    </span>
                    <i className="fas fa-long-arrow-alt-right right-icon"></i>
                  </div>
                </>}

                <div className='close-sharer' itemID={'Close'}>
                  <i className="fas fa-times"></i>{trans('checkout.closeWindow')}
                </div>

              </div>
            </div>
          </div>
        </>}
      </div>
    );
  }
}
