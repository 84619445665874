import * as React from 'react';
import * as core from '../../model/core';
import { autobind } from '../../decorator';
import { ViewType, getViewHandler } from '../handler';
import { RemoveChangeEvent, HistoryState } from '../handler/viewHandler';
import * as query from '../../query';
import * as api from '../../api/vehicleFilterApi';
import { PaymentCalculator } from './paymentCalculator';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { formatNumber, capitalize, getEnumStringValue, getEnumValue, className, setBaseUrl, baseUrl } from '../../util';
import { FaIcon } from '../../icon';
import { ObjectViewSkeleton } from '../../skeleton';
import { SimilarObjects } from './similarObjects';
import { InterestForm } from './form/interestForm';
import { ActionButton } from './form/input';
import { InterestFormCompact } from './form/interestFormCompact';
import { InterestFormSimilarObjects } from './form/interestFormSimilarObjects';
import { translate as trans, translateAny as transAny, translateFormat as transFormat, setLanguage } from '../../lang';
import { Checkout } from '../../checkout';
import { Images360 } from './images360';
import { Specification } from './specification';
import { ShareVehicle } from './shareVehicle';
import { VehicleIcon } from '../vehicleIcon';
import { FileResources } from './fileResources';
import { Favourites, ManageVehicleStorage, vStorageStr, useFavourites } from '../../favourites';
import { PrintVehicles } from './printVehicles';
import { VehicleSingleSchema } from '../../dataSchemas';

enum InterestFormSelection {
  default,
  lite,
  compact
}

export interface ObjectSectionSettings {
  id?: string;
  interestForm?: string;
}

interface ObjectQueryParameters {
  item: string;
}

const querySchema: query.QueryStringSchema<ObjectQueryParameters> = {
  item: query.string()
};

interface ObjectViewProps {
  objectId?: string;
  object?: core.Vehicle | null;
  selectRelatedObject: (object: core.Vehicle) => void;
  relatedObjectLink?: (object: core.Vehicle) => string | undefined | null;
  settings?: ObjectSectionSettings;
  config: core.GeneralConfig;
}

interface ObjectViewState {
  currentObject?: core.VehicleExtended | null;
  loading: boolean;
  showBuyOnline: boolean;
  showMoreEquipment: boolean;
  showVideo: boolean;
  images360: string[] | null;
  favouriteStorage: string;
  printStatus: string;
}

export class ObjectView extends React.Component<ObjectViewProps, ObjectViewState> {

  private notificationOfInterestElm: HTMLElement | null = null;
  private removeViewChangeEvent: RemoveChangeEvent | null = null;

  constructor(props: ObjectViewProps) {
    super(props);

    this.state = {
      currentObject: null,
      loading: true,
      showBuyOnline: false,
      showMoreEquipment: false,
      showVideo: false,
      images360: null,
      favouriteStorage: localStorage.getItem(vStorageStr) ?? '',
      printStatus: trans('object.action.pdf')
    };
  }

  async rehydrateObject(uniqueId?: string) {
    const id = uniqueId ?? this.props.object?.uniqueId ?? this.state.currentObject?.uniqueId;

    if (!id) {
      return;
    }

    const currentObject = await api.getObject(id);

    if (currentObject) {
      setLanguage(currentObject.language);
      setBaseUrl(currentObject.baseUrl);
    }

    this.setState({
      currentObject,
      loading: false
    });
  }

  loadObject(objectId?: string) {
    const id = objectId ?? this.props.objectId;
    if (id) {
      // Fetch object using an externally selected id.
      this.rehydrateObject(id);
      return;
    }

    const prevView = getViewHandler().getView();

    if (this.props.object) {
      // If object id is coming from clicked search result item.
      const queryString = query.objectToQueryString({
        item: this.props.object.friendlyId ?? this.props.object.uniqueId
      } as ObjectQueryParameters);

      getViewHandler().setView(ViewType.Object, queryString, prevView?.view !== ViewType.Object);

      this.rehydrateObject(this.props.object.uniqueId);
    } else if (prevView && prevView.view === ViewType.Object) {
      // If object id is coming from the url. Happens on a hard page reload.
      const queryParams = query.queryStringToObject<ObjectQueryParameters>(prevView.query ?? '', querySchema);

      if (queryParams && queryParams.item && !this.state.currentObject) {
        this.rehydrateObject(queryParams.item);
      }
    }

    window.scrollTo(0, 0);
  }

  componentDidMount() {
    this.loadObject();

    this.removeViewChangeEvent = getViewHandler().onViewChange((view, url) => {
      // Handle history back/forward and keep the same view. Used for related vehicles.
      const queryString = view.query ?? '';
      const queryParams = query.queryStringToObject<ObjectQueryParameters>(queryString, querySchema);

      if (queryParams && queryParams.item) {
        this.loadObject(queryParams.item);
        window.scrollTo(0, 0);
      }
    });
  }

  componentWillUnmount() {
    if (this.removeViewChangeEvent) {
      this.removeViewChangeEvent();
    }
  }

  componentDidUpdate(prevProps: ObjectViewProps) {
    if (this.props.object?.uniqueId !== prevProps.object?.uniqueId) {
      this.setState({ loading: true }, () => this.loadObject());
    }
  }

  getInterestForm(vehicle: core.VehicleExtended) {
    const selectedInterestForm = getEnumValue(InterestFormSelection, this.props.settings?.interestForm ?? '');

    switch ((selectedInterestForm as any) as InterestFormSelection) {
      case InterestFormSelection.lite:
        return <InterestForm
          vehicle={vehicle}
          lite={true}
          ref={elm => this.notificationOfInterestElm = elm?.element ?? null} />;

      case InterestFormSelection.compact:
        return <InterestFormCompact
          vehicle={vehicle}
          ref={elm => this.notificationOfInterestElm = elm?.element ?? null} />;

      default:
        return <InterestForm
          vehicle={vehicle}
          ref={elm => this.notificationOfInterestElm = elm?.element ?? null} />;
    }
  }

  render() {
    const { currentObject, loading } = this.state;
    const { config } = this.props;

    if (!currentObject) {
      if (loading) {
        return <ObjectViewSkeleton />;
      }

      return <div className='dso-object-notfound'>{trans('object.notFound')}</div>;
    }

    const isCampaign = !!currentObject.salePrice &&
      currentObject.salePrice > 0 &&
      currentObject.salePrice !== currentObject.price;
    const price = currentObject.price;
    const hasStatus = currentObject.status !== null && currentObject.status !== '';
    const salePrice = currentObject.salePrice || 0;
    const shownPrice = isCampaign ? salePrice : price;
    const costCalculationValues = currentObject.costCalculationValues;

    let classname = 'dso-object-status-title';

    if (hasStatus) {
      classname += '-' + currentObject.status;
    }

    // Check if there are images, both static gallery images
    const images = currentObject.images && currentObject.images.length > 0
      ? currentObject.images
      : ['/i/blank?mw=770&mh=500'];

    // Convert description string to arrays, then put them in correct elements depending on array item string length
    // Logic is messy since we don't have access to strict data through VDS api

    const isNorwegian = currentObject.language === 'nb';

    const descText = currentObject.description != null
      ? currentObject.description.split(',').map(item => item.replace(/<[^>]*>?/gm, ''))
      : currentObject.description;
    const longDescItems = descText != null
      ? descText.filter(str => str.length >= 25)
      : descText;
    const shortDescItems = descText != null
      ? descText.filter(str => str.length < 25).map(obj => ({'name': obj}))
      : descText;
    let descriptionItems = (/* currentObject.type !== core.VehicleType.car && */ shortDescItems != null && !isNorwegian)
      ? currentObject.descriptionEquipments.concat(shortDescItems)
      : currentObject.descriptionEquipments;

    // If heating exists, throw it into the description list
    if (currentObject.heating && currentObject.heating.length > 0) {
      descriptionItems = descriptionItems
        .concat({name: currentObject.heating.toString()})
        .filter(item =>
          (item.name !== 'Alde vattenburen värme') &&
          (item.name !== 'Truma luftburen värme'));
      // Will need another solution if we're gonna filter out more heating sources (VERDSO-158)
    }

    // Norweigan constants
    let norDesc = currentObject.description != null
      ? currentObject.description.split('*')
      : currentObject.description;
    let norLong = norDesc != null
      ? norDesc.filter(str => str.length > 50).join('').replace(/(?:\r\n|\r|\n)/g, '<br>')
      : norDesc;
    let norShort = norDesc != null
      ? norDesc.filter(str => str.length <= 50).map(obj => ({'name': obj.replace('\n','')}))
      : norDesc;

    if (isNorwegian && (norShort != null)) {
      descriptionItems = descriptionItems.concat(norShort);
    }

    if (currentObject) {
      VehicleSingleSchema(currentObject);
    }

    return (
      <div className='dso-object-container'>
        <div className='dso-object-top'>
          <div className='dso-object-title'>
            <BackButton />
            <h1>
              {currentObject.type === core.VehicleType.car
                ? currentObject.title
                : currentObject.brand + ' ' + currentObject.model }
            </h1>
          </div>
        </div>

        <div className='dso-object-main-container'>
          <div className='dso-object-main-images'>

            {isCampaign && currentObject.status !== 'sold' && <>
              <div className='dso-object-campaign-container'>
                <div className='dso-object-campaign-title'>
                  {trans('object.campaignTitle')}
                </div>

                <div className='dso-object-campaign-price'>
                  {currentObject.priceExTax
                    ? transFormat('object.campaignPrice', formatNumber((price * 1.25) - (salePrice * 1.25)))
                    : transFormat('object.campaignPrice', formatNumber(price - salePrice))}
                </div>

              </div>
            </>}

            {currentObject.youTube !== null && <>
              <div className='dso-object-youtube-button'>
                <ActionButton
                  icon='play'
                  label={trans('object.action.youTube')}
                  click={() => { window.open(currentObject.youTube, '_blank');}} />
              </div>
            </>}

            {hasStatus && <>
              <div className={classname + ' ' + currentObject.status}>
                {transAny('object.status.' + currentObject.status?.toLowerCase())}</div>
            </>}

            <ImageGallery
              lazyLoad={true}
              showBullets={false}
              showPlayButton={false}
              showFullscreenButton={false}
              renderItem={(item: ReactImageGalleryItem) => {
                return <div className='main-image-container'>
                  <img src={item.original} />
                </div>;
              }}
              showNav={true}
              showThumbnails={true}
              autoPlay={false}
              items={images.map(img => ({
                original: img,
                thumbnail: img,
                originalClass: 'dso-main-image'
              }) as ReactImageGalleryItem)} />
          </div>

          <div className='dso-object-main-side'>

            {!hasStatus && <>

              <div className='dso-object-price-container'>

                {/* Price to show for all objects */}
                <div className='dso-object-price'>
                  {currentObject.priceExTax
                    ? transFormat('object.price', formatNumber(shownPrice  * 1.25))
                    : transFormat('object.price', formatNumber(shownPrice))}
                </div>

                {/* Show incl. equipment string if object has any equipment */}
                {currentObject.equipment && currentObject.equipment.length > 0 && <>
                  <div className='dso-object-price-details'>{trans('object.priceDetails')}</div>
                </>}

                {/* Show ordinary price if object is a campaign */}
                {isCampaign &&
                  <span className='dso-object-alt-price ordPrice'>
                    <span className='vat-value'>
                      {currentObject.priceExTax
                        ? transFormat('object.price', formatNumber(price * 1.25))
                        : transFormat('object.price', formatNumber(price))}
                    </span>
                    <span className='vat-text'> {trans('object.orgPrice')}</span>
                  </span>}

                {/* Show ex tax price */}
                {currentObject.priceExTax &&
                  <span className='dso-object-alt-price exVat'>
                    <span className='vat-value'>{transFormat('object.price', formatNumber(shownPrice))}</span>
                    <span className='vat-text'> {trans('object.priceExVat')}</span>
                  </span>}

              </div>

                {costCalculationValues && <>
                    <PaymentCalculator
                      downPayment={{
                        min: costCalculationValues.downPaymentMinimum,
                        max: costCalculationValues.downPaymentMaximum
                      }}
                      initialDownPayment={costCalculationValues.initialDownPayment}
                      interest={costCalculationValues.loanInterest}
                      months={{
                        min: costCalculationValues.monthSteps ?? 1, // Months cannot be 0, use step as min value.
                        max: costCalculationValues.loanPaymentYearsMaximum * 12,
                        step: costCalculationValues.monthSteps
                      }}
                      startMonth={costCalculationValues.initialLoanPaymentYears * 12}
                      minMonthlyCost={costCalculationValues.monthlyPaymentMinimum}
                      price={shownPrice}
                      lang={currentObject.language}
                      stateFee={costCalculationValues.stateFee}
                      setupFee={costCalculationValues.setupFee}
                      invoiceFee={costCalculationValues.invoiceFee} />
                </>}

              <div className='dso-action-buttons-container'>
                {currentObject.buyOnline && <>
                  <ActionButton positive={true} icon='shopping-cart' label={trans('object.action.buy')} click={() => {
                    this.setState({ showBuyOnline: true });
                    document.body.classList.add('buyOnlineModal-open');
                  }} />
                </>}

                <ActionButton icon='envelope' label={trans('object.action.interestForm')} click={() => {
                  if (this.notificationOfInterestElm) {
                    window.scrollTo({
                      top: this.notificationOfInterestElm.offsetTop,
                      behavior: 'smooth'
                    });
                  }
                }} />

                {currentObject.mainPhoneNumber !== '' &&
                  <ActionButton icon='phone' label={trans('object.action.call')} click={() => {
                    window.location.href = `tel:${currentObject.mainPhoneNumber}`;
                  }} />}

                <div className='dso-extra-action-buttons'>
                  {(config.share.facebook || config.share.messenger ||
                    config.share.link || config.share.mail) && <>
                    <ShareVehicle
                      link={window.location.href}
                      sharing={config.share} /></>}

                  {/* {(config.print.active && <>
                    <ActionButton
                      icon={'file-pdf'}
                      label={this.state.printStatus}
                      click={() => PrintVehicles({
                        vehicles: [currentObject],
                        config: config
                      }, (str) => this.setState({ printStatus: str }))}
                      inlineStyle={true} /></>) } */}

                  {useFavourites &&
                    <ManageVehicleStorage
                      objectId={currentObject.uniqueId}
                      storageVehicles={this.state.favouriteStorage}
                      updateStorage={(str) => this.setState({ favouriteStorage: str })} />}
                </div>

              </div>
            </>}

            {hasStatus && <>
              <InterestFormSimilarObjects
                vehicle={currentObject}
                ref={elm => this.notificationOfInterestElm = elm?.element ?? null} />
            </>}

          </div>
        </div>

        {hasStatus && <>
          <SimilarObjects
            objects={currentObject.relatedObjects}
            objectClick={this.props.selectRelatedObject}
            objectLink={this.props.relatedObjectLink}
            hasStatus={hasStatus}
            objectType={currentObject.type}
            storageVehicles={this.state.favouriteStorage}
            updateStorage={(str) => this.setState({ favouriteStorage: str })}
            listView={config.defaultGridView}
            showSuggestedMonthCost={config.suggestedMonthCost} />
        </>}

        {/*
        TODO: Verendus ska återkomma med API för "test_report".
        currentObject.testReport && <>
          <div className='dso-object-testreport'>
            <a href='#'>Visa testrapport</a>
            <ul>
              <li><FaIcon name='check-circle-o'/> Varudeklarerad</li>
              <li><FaIcon name='check-circle-o'/> Fukttestad</li>
              <li><FaIcon name='check-circle-o'/> Gasoltestad</li>
            </ul>
          </div>
        </>*/}

        {(!currentObject.isSold && (!hasStatus)) && currentObject.filial && <>
          <div className='dso-object-top-bar'>
            <div className='filial-container'>
              <i className='fa fa-warehouse'></i>{trans('object.filialDesc')} <strong>{currentObject.filial}</strong>
            </div>
            {/* <VehicleIcon
              name={currentObject.category.toLocaleLowerCase()}
              cls='dso-object-icon'
              type={currentObject.type} /> */}
          </div>
        </>}

        {currentObject.adInfo !== null && <>
          <div className='dso-object-adinfo-container'>
            <h2>{trans('object.descHeader')}</h2>
            <div dangerouslySetInnerHTML={{ __html: currentObject.adInfo }}></div>
          </div>
        </>}

        {(currentObject.source === 'bilvision') && (currentObject.description) && <>
          <div className='dso-object-adinfo-container'>
            <h2>{trans('object.descHeader')}</h2>
            <div dangerouslySetInnerHTML={{ __html: currentObject.description }}></div>
          </div>
        </>}

        <Specification vehicle={currentObject} showArticleNr={config.showArticleNr} />
        <Images360 images={currentObject.images360} />

        {(currentObject.equipment && currentObject.equipment.length > 0) ||
        (descriptionItems && descriptionItems.length > 0) ||
        (longDescItems && longDescItems.length > 0)
          ? <h2>{trans('object.equipmentTitle')}</h2>
          : ''}

        {currentObject.equipment && currentObject.equipment.length > 0 && <>
          <div className='dso-object-extra-equipment-container'>
            <div className='dso-object-extra-equipment-list'>
              {currentObject.equipment.map(item =>
                <EquipmentItem key={item.articleNr} item={item} />)}
            </div>
            <div className='dso-object-equipment-total-price-container'>
              <div className='dso-object-equipment-total-price'> 
                <span className='dso-object-equipment-total-price-desc'>{trans('object.equipmentDesc')}</span>
                <span className='dso-object-equipment-total-price-sum'>{formatNumber(
                  currentObject.equipment.reduce(
                    (prev, cur) => prev + cur.price, 0)
                )} {trans('payment.default.currency')}</span>
              </div>
            </div>
          </div>
        </>}

        {descriptionItems.length > 0 && <>
          <div className='dso-object-inclusive-equipment-container'>
            <div className='dso-object-description-equipment'>
              <ul className={className('dso-object-description-equipment-list', {
                'view-more-equipment': descriptionItems.length > 12,
                'view-more-equipment-visible': this.state.showMoreEquipment
              }) }>

                {descriptionItems.filter(item => (item.name !== '') && (item.name !== ' ')).map(spec =>
                  <DescEquipItem key={spec.name} item={spec} />)}
              </ul>

              {descriptionItems.length > 12 && <>
                <div className='dso-object-description-equipment-show-all'>
                  <div className='dso-object-description-equipment-show-all-button'>
                    <div onClick={() => this.setState({ showMoreEquipment: !this.state.showMoreEquipment })}>
                      <span>
                        {!this.state.showMoreEquipment
                          ? trans('object.showMoreEquipment')
                          : trans('object.showLessEquipment')}
                      </span>
                      {!this.state.showMoreEquipment
                        ? <FaIcon name='chevron-down' />
                        : <FaIcon name='chevron-up' />}
                    </div>
                  </div>
                </div>
              </>}
            </div>
          </div>
        </>}

        {isNorwegian ? <>
          {norLong && <>
            <div className='dso-object-description-equipment-desc'
              dangerouslySetInnerHTML={{ __html: norLong }}></div>
          </>}
        </>:<>
          {/* {(currentObject.type === core.VehicleType.car) && (currentObject.source !== 'bilvision') ? <>
            {currentObject.description && <>
              <div className='dso-object-description-equipment-desc'
                dangerouslySetInnerHTML={{ __html: currentObject.description }}></div>
            </>} */}
          {/* </>:<> */}
            {(longDescItems != null) && (longDescItems.length > 0) && (currentObject.source !== 'bilvision') && <>
              <div className='dso-object-description-equipment-desc'>
                {longDescItems.map(item =>
                  <DescriptionListItem item={item} />
                )}
              </div>
            </>}
          </>}
        {/* </> } */}

        <FileResources vehicle={currentObject} />

        {!hasStatus && <>
          {this.getInterestForm(currentObject)}

          <SimilarObjects
            objectLink={this.props.relatedObjectLink}
            objects={currentObject.relatedObjects}
            objectClick={this.props.selectRelatedObject}
            objectType={currentObject.type}
            storageVehicles={this.state.favouriteStorage}
            updateStorage={(str) => this.setState({ favouriteStorage: str })}
            listView={config.defaultGridView}
            showSuggestedMonthCost={config.suggestedMonthCost} />
        </>}

        {this.state.showBuyOnline && <>
          <div id='checkoutPortal' className='dso-portal-container-custom'>
            <div className='dso-portal-content-container-custom'>

              <div className='dso-portal-close-custom' onClick={() => {
                this.setState({ showBuyOnline: false });
                document.body.classList.remove('buyOnlineModal-open');
              }}>
                Stäng fönstret <FaIcon name='times' />
              </div>

              <div className='dso-portal-content-custom'>
                <Checkout
                  type={currentObject.financeType}
                  showExchange={currentObject.showExchange}
                  articleNr={currentObject.articleNr}
                  costCalc={currentObject.costCalculationValues} />
              </div>
            </div>
          </div>
        </>}

        {useFavourites &&
          <Favourites
            storageVehicles={this.state.favouriteStorage}
            updateStorage={(str) => this.setState({ favouriteStorage: str })}
            config={config} />}

      </div>
    );
  }
}

interface DescEquipItemProps {
  item: core.DescriptionEquipment;
}

export function DescEquipItem({ item }: DescEquipItemProps) {

  let str = item.name;

  // Remove space at the beginning of string
  if (str.charAt(0) === ' ') {
    str = str.substr(1);
  }

  // Check for first letters as lower case
  if (str.charAt(0) !== str.charAt(0).toUpperCase()) {
    str = str.charAt(0).toUpperCase() + str.slice(1);
  }

  // Remove space at the end of string
  if (str.charAt(str.length - 1) === ' ') {
    str = str.slice(0, -1);
  }

  return (
    <li>
      <FaIcon name='check' /> {str}
    </li>
  );
}

interface EquipmentItemProps {
  item: core.VehicleEquipment;
}

export function EquipmentItem({ item }: EquipmentItemProps) {
  return (
    <div className='dso-object-equipment'>
      <div className='dso-object-equipment-name'>{item.name}</div>
      <div className='dso-object-equipment-price'>{transFormat('object.price', formatNumber(item.price))}</div>
    </div>
  );
}

interface DescriptionItemProps {
  item: string;
}

function DescriptionListItem({ item }: DescriptionItemProps) {

  let str = item;

  // Remove space at the beginning of string
  if (str.charAt(0) === ' ') {
    str = str.substr(1);
  }

  // Check for first letters as lower case
  if (str.charAt(0) !== str.charAt(0).toUpperCase()) {
    str = str.charAt(0).toUpperCase() + str.slice(1);
  }

  // Remove space at the end of string
  if (str.charAt(str.length - 1) === ' ') {
    str = str.slice(0, -1);
  }

  // Add a dot at the end of string
  if (str.charAt(str.length - 1) !== '.') {
    str = str + '.';
  }

  return(
    <div className='dso-description-list-item'><FaIcon name={'check'} />{str}</div>
  );

}

function BackButton() {
  const historyState = window.history.state as HistoryState;

  if (!historyState) {
    return null;
  }

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    if (historyState) {
      window.history.back();
    } else {
      window.location.href = window.location.pathname;
    }
  };

  return (
    <div title={trans('object.goBack')} className='dso-back-button' onClick={handleClick}>
      <span><FaIcon name={'arrow-left'} /></span>
    </div>
  );
}