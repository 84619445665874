import * as React from 'react';
import { VehicleType } from '../model/core';

interface Icon {
  cssName: string;
  names: string[];
}

const makeIcon = (cssName: string, ...names: string[]) => ({ cssName, names } as Icon);

const icons: Icon[] = [
  // Mobile home
  makeIcon('alcove', 'alcove', 'alkov', 'alkove', 'alkové'),
  makeIcon('half-integrated', 'halvintegrerad', 'half integrated'),
  makeIcon('half-integrated-roofbed', 'halvintegrerad med takbädd', 'half integrated with roofbed'),
  makeIcon('integrated', 'integrerad', 'integrated'),
  makeIcon('default-mobilehome'),

  // Caravan
  makeIcon('single-axle', 'enkelaxel', 'single axle'),
  makeIcon('double-axle', 'dubbelaxel', 'double axle'),
  makeIcon('default-caravan'),

  // Car
  makeIcon('coupe', 'coupé', 'coupe', 'kupé', 'kupe', 'sports coupe'),
  makeIcon('halvkombi', 'halvkombi', 'hatchback'),
  makeIcon('kombi', 'kombi', 'combi'),
  makeIcon('minibuss', 'minibuss', 'mini buss', 'mini bus', 'minibus'),
  makeIcon('pickup', 'pickup', 'truck'),
  makeIcon('sedan', 'sedan'),
  makeIcon('skapbil', 'skapbil', 'skåpbil', 'delivery van'),
  makeIcon('suv', 'suv'),
  makeIcon('van', 'van'),
  makeIcon('yrkesfordon', 'yrkesfordon', 'work van'),
  makeIcon('cab', 'cab', 'cabriolet', 'cabriolét', 'kab'),
  makeIcon('default-car'),

  // Mc / Atv
  makeIcon('cross', 'cross/enduro/trial'),
  makeIcon('custom-mc', 'custom'),
  makeIcon('four-wheel', '4-wheeler'),
  makeIcon('other-mc'),
  makeIcon('moped', 'moped/eu-moped'),
  makeIcon('offroad-mc', 'offroad', 'off road'),
  makeIcon('scooter', 'scooter'),
  makeIcon('sport-mc', 'sport', 'sport mc'),
  makeIcon('touring-mc', 'touring/country road'),
  makeIcon('trike', 'trike'),
  makeIcon('default-mcatv'),

  // Snowmobile
  makeIcon('snowmobile', 'snowmobile', 'snow mobile'),
  makeIcon('default-snowmobile'),

  // Boat
  makeIcon('hardtop', 'hardtop'),
  makeIcon('daycruiser', 'daycruiser'),
  makeIcon('walkaround', 'walkaround'),
  makeIcon('cabin', 'cabin'),
  makeIcon('bowrider', 'bowrider'),
  makeIcon('mittcabin', 'mittcabin'),
  makeIcon('styrpulpet', 'styrpulpet'),
  makeIcon('rowing-boat', 'rowing boat'),
  makeIcon('rib', 'rib'),
  makeIcon('jet-ski', 'jet ski'),
  makeIcon('sailboat', 'sailboat', 'sail boat'),
  makeIcon('default-boat'),

  // Transport
  makeIcon('default-trailer-transport'),

  // Other - fallback for all
  makeIcon('default', 'övrigt')

];

interface VehicleIconProps {
  name: string;
  cls?: string;
  type: number;
}

export function VehicleIcon(props: VehicleIconProps) {
  let defaultStr = '';
  switch (props.type) {
    case VehicleType.car:
      defaultStr = 'default-car';
      break;
    case VehicleType.caravan:
      defaultStr = 'default-caravan';
      break;
    case VehicleType.mobileHome:
      defaultStr = 'default-mobilehome';
      break;
    case VehicleType.mcAtv:
      defaultStr = 'default-mcatv';
      break;
    case VehicleType.snowmobile:
      defaultStr = 'default-snowmobile';
      break;
    case VehicleType.boat:
      defaultStr = 'default-boat';
      break;
    case VehicleType.trailer || VehicleType.transport:
      defaultStr = 'default-trailer-transport';
      break;
    default:
      defaultStr = 'default';
      break;
  }
  const name = props.name?.toLowerCase();
  const icon = icons.find(i => i.names.indexOf(name) > -1)?.cssName ?? defaultStr;
  let cls = 'dso-vehicle-icon-container';

  if (props.cls) {
    cls += ' ' + props.cls;
  }

  return (
    <span className={cls}>
      <i className={'dso-vehicle-icon dso-vehicle-icon-' + icon}></i>
    </span>
  );
}