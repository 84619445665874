import * as React from 'react';
import * as noUiSlider from 'nouislider';
import { formatNumber, className } from '../../../util';
import { Portal } from '../../portal';
import { translate as trans, translateFormat as transFormat } from '../../../lang';
import * as core from '../../santander.core';
import * as santanderApi from '../api/santanderApi';
import { debounce } from '../../../decorator';

interface Range {
  min: number;
  max: number;
  step?: number;
}

interface PaymentCalculatorProps {
  articleNr: string;
  price: number;
  interest: number;
  months: Range;
  startMonth: number;
  downPayment: Range;
  initialDownPayment?: number;
  minMonthlyCost?: number;
  disclaimerText?: Object[];

  downPaymentChange?: (value: number) => void;
  monthChange?: (value: number) => void;
  ecster?: boolean; // Remove later
}

interface PaymentCalculatorState {
  downPayment: number;
  month: number;
  showInfoModal: boolean;
  disclaimer?: core.InfoTextGroup;
}

export class PaymentCalculator extends React.Component<PaymentCalculatorProps, PaymentCalculatorState> {

  private downPaymentSliderElm?: HTMLElement;
  private downPaymentSlider?: noUiSlider.noUiSlider;
  private loanTimeElm?: HTMLElement;
  private loanTimeSlider?: noUiSlider.noUiSlider;

  private costLimitReached = false;
  private lastCostLimit = false;

  constructor(props: PaymentCalculatorProps) {
    super(props);

    this.state = {
      downPayment: (props.initialDownPayment ?? props.downPayment.min) / 100,
      month: props.startMonth,
      showInfoModal: false
    };
  }

  private initSliders() {
    if (this.downPaymentSliderElm) {
      this.downPaymentSlider = noUiSlider.create(this.downPaymentSliderElm, {
        start: this.state.downPayment,
        connect: [true, false],
        range: {
          min: Math.max(this.props.downPayment.min / 100, 0),
          max: Math.min(this.props.downPayment.max / 100, 1)
        }
      });

      this.downPaymentSlider.on('update', values => {
        const downPayment = +values[0];
        this.setState({ downPayment });

        if (this.props.downPaymentChange) {
          this.props.downPaymentChange(downPayment * 100);
        }

        this.fetchDisclaimer();
      });
    }

    if (this.loanTimeElm) {
      this.loanTimeSlider = noUiSlider.create(this.loanTimeElm, {
        start: this.props.startMonth,
        connect: [true, false],
        step: this.props.months.step ?? 1,
        range: {
          min: this.props.months.min ?? 1,
          max: Math.max(this.props.months.max, 1)
        }
      });

      this.loanTimeSlider.on('update', values => {
        const month = +values[0];
        this.setState({ month });

        if (this.props.monthChange) {
          this.props.monthChange(month);
        }

        this.fetchDisclaimer();
      });
    }
  }

  componentDidMount() {
    try {
      this.initSliders();
    } catch (ex) {
      console.error(ex);
    }

    this.fetchDisclaimer();
  }

  componentDidUpdate() {
    if (this.lastCostLimit === this.costLimitReached) {
      return;
    }

    const updateSliderLimit = (slider: noUiSlider.noUiSlider, max: number) => {
      try {
        slider.updateOptions({
          padding: this.costLimitReached ? [0, +slider.options.range.max - max] as any : 0
        }, true);
      } catch (ex) {
        console.error(ex);
      }
    };

    if (this.downPaymentSlider) {
      updateSliderLimit(this.downPaymentSlider, this.state.downPayment);
    }

    if (this.loanTimeSlider) {
      updateSliderLimit(this.loanTimeSlider, this.state.month);
    }

    this.lastCostLimit = this.costLimitReached;
  }

  @debounce(300)
  async fetchDisclaimer() {
    const texts = await santanderApi.getArticleInfoTexts(this.props.articleNr, {
      creditTimeMonths: this.state.month,
      downPayment: this.state.downPayment * this.props.price,
      type: core.InfoTextType.disclaimer
    });

    this.setState({ disclaimer: texts.textGroups[0] });
  }

  render() {
    const downPayment = this.state.downPayment * this.props.price;
    const months = this.state.month;
    const interest = 1 + (this.props.interest / 100);
    const monthCost = ((this.props.price - downPayment) / months) * interest;
    const loanCosts = ((this.props.price - downPayment) * interest) - (this.props.price - downPayment);

    // New calculation variables
    const santanderInterest = (this.props.interest / 12) * 0.01
    const totalLoan = (this.props.price - downPayment)
    const monthlyCost = totalLoan * (santanderInterest / (1 - Math.pow( (1 + santanderInterest), (-months) )) )
    const totalInterestCost = (monthlyCost * months) - totalLoan

    this.costLimitReached = this.props.minMonthlyCost != null && monthlyCost < this.props.minMonthlyCost;

    return (
      <div className='empori-payment-calc'>

        <div className='empori-payment-montly-cost'>
          <h4>{trans('payment.monthly')}</h4>
          {transFormat('payment.monthlyCost', formatNumber(monthlyCost /*monthCost*/))}
        </div>

        <div className={className('dso-payment-calc-limit', { 'dso-visible': this.costLimitReached })}>
          <span><i className='fas fa-exclamation-triangle'></i>{transFormat('payment.calcInfo.limit', formatNumber(this.props.minMonthlyCost!))}</span>
        </div>

        <Slider
          label={trans('payment.downPayment.title')}
          value={transFormat('payment.downPayment.value', formatNumber(downPayment))}
          elm={elm => this.downPaymentSliderElm = elm as HTMLElement} />

        <Slider
          label={trans('payment.loanTime.title')}
          value={transFormat('payment.loanTime.value', formatNumber(this.state.month))}
          elm={elm => this.loanTimeElm = elm as HTMLElement} />

        <div className='empori-payment-calc-info-container'>

          <div className='empori-portal-content-title'>{trans('payment.calcInfo.title')}</div>
          <div className='empori-portal-content-calculation'>
            <div className='empori-portal-content-calculation-field'>
              <span className='empori-portal-calc-subtitle'>{transFormat('payment.calcInfo.downPayment', this.props.downPayment.min)}</span>
              &nbsp;
              <span className='empori-portal-calc-subtitle-price'>{formatNumber(downPayment)} kr</span>
            </div>
            <div className='empori-portal-content-calculation-field'>
              <span className='empori-portal-calc-subtitle'>{trans('payment.calcInfo.loan')}</span>
              &nbsp;
              <span className='empori-portal-calc-subtitle-price'>{formatNumber(totalLoan)} kr</span>
            </div>
            <div className='empori-portal-content-calculation-field'>
              <span className='empori-portal-calc-subtitle'>{trans('payment.calcInfo.interestCost')}</span>
              &nbsp;
              <span className='empori-portal-calc-subtitle-price'>{formatNumber(totalInterestCost /*loanCosts*/)} kr</span>
            </div>
            <div className='empori-portal-content-calculation-field total'>
              <span className='empori-portal-calc-subtitle'>{trans('payment.calcInfo.total')}</span>
              &nbsp;
              <span className='empori-portal-calc-subtitle-price'>{formatNumber(this.props.price + totalInterestCost)} kr</span>
            </div>

            <div className='empori-portal-content-calculation-summary'>
              {this.state.disclaimer && this.state.disclaimer.texts.map(d =>
                <span key={d.id} dangerouslySetInnerHTML={{ __html: d.text }}></span>)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

interface SliderProps {
  elm: (elm: HTMLElement) => void;
  label: string;
  value: string;
}

function Slider(props: SliderProps) {
  return (
    <div className='empori-payment-slider'>
      <div className='empori-payment-slider-label'>{props.label}</div>
      <div className='empori-payment-slider-value'>{props.value}</div>
      <div className='empori-payment-slider-elm' ref={elm => props.elm(elm as HTMLElement)}></div>
    </div>
  );
}
