import * as React from 'react';
import * as core from '../../model/core';
import { FaIcon } from '../../icon';
import { translate as trans, translateAny as transAny, translateFormat as transFormat } from '../../lang';
import { className } from '../../util';

interface VehicleObjectSpecProps {
  value: string | number;
  icon: string;
}

function VehicleObjectSpec(props: VehicleObjectSpecProps) {
  const { value, icon } = props;
  return (
    <div className='dso-object-spec'>
      <div className='dso-object-spec-value'>
        <FaIcon name={icon} />{value}
      </div>
    </div>
  );
}

interface ThumbnailSpecProps {
  vehicle: core.Vehicle;
}

export function VehicleThumbnailSpec(props: ThumbnailSpecProps) {
  const { vehicle } = props;

  return(
    <div key={vehicle.uniqueId}
      className={className('dso-object-specs-filter-container', {fewSpecs:
        (vehicle.type !== core.VehicleType.caravan) &&
        (vehicle.type !== core.VehicleType.mobileHome) &&
        (vehicle.type !== core.VehicleType.unknown)})}>

      {/* Cars */}
      {vehicle.type === core.VehicleType.car && <>

        {vehicle.milage != null && <>
          <VehicleObjectSpec
            key={'car-milage'}
            value={vehicle.milage + ' ' + trans('object.specItem.milageUnit').toLowerCase()}
            icon={'road'}/>
        </>}

        {vehicle.effect != null && <>
          <VehicleObjectSpec
            key={'car-effect'}
            value={vehicle.effect + ' ' + trans('filter.input.effect.unit').toLowerCase()}
            icon={'dashboard'} />
        </>}
      </>}

      {/* Snow mobiles */}
      {vehicle.type === core.VehicleType.snowmobile && <>

        {vehicle.milage != null && <>
          <VehicleObjectSpec
            key={'snow-mobile-milage'}
            value={vehicle.milage + ' ' + trans('object.specItem.milageUnit').toLowerCase()}
            icon={'road'} />
        </>}
      </>}

      {/* Trailers */}
      {vehicle.type === core.VehicleType.trailer && <>

        {vehicle.specifications
          .filter(findArr => findArr.title.includes('Totalvikt'))
          .map(spec => spec.value !== '0 kg' &&
            <VehicleObjectSpec
              key={'trailer-weight'}
              value={spec.value}
              icon={'balance-scale'} />
          )}

        {vehicle.specifications
          .filter(findArr => findArr.title.includes('Längd'))
          .map(spec => spec.value !== '0 cm' &&
            <VehicleObjectSpec
              key={'trailer-length'}
              value={spec.value}
              icon={'expand'} />
          )}
      </>}

      {/* Caravans */}
      {vehicle.type === core.VehicleType.caravan && <>

        {vehicle.beds > 0 && <>
          <VehicleObjectSpec
            key={'caravan-beds'}
            value={vehicle.beds}
            icon={'bed'} />
        </>}

        {vehicle.layout !== null && <>
          <VehicleObjectSpec
            key={'caravan-layout'}
            value={transAny('filter.input.layouts.' + vehicle.layout.toLowerCase())}
            icon={'expand'} />
        </>}

        {vehicle.specifications
          .filter(item => item.title.includes('Totalvikt'))
          .map(spec => spec.value !== '0 kg' &&
            <VehicleObjectSpec
              key={'caravan-total-weight'}
              value={spec.value}
              icon={'balance-scale'} />
          )}
      </>}

      {/* Mobile homes */}
      {vehicle.type === core.VehicleType.mobileHome && <>

        {vehicle.milage > 0 && <>
          <VehicleObjectSpec
            key={'mobile-home-milage'}
            value={vehicle.milage + ' ' + trans('object.specItem.milageUnit').toLowerCase()}
            icon={'road'} />
        </>}

        {vehicle.essentialSpecs
          .filter(item => item.title.includes('Växelåda'))
          .map(spec => spec.value !== null && <>
            <VehicleObjectSpec
              key={'mobile-home-gear'}
              value={spec.value === 'Automatic'
                ? trans('object.specItem.gearAuto')
                : trans('object.specItem.gearManual')}
              icon={'code-fork'} />
        </>)}

        {vehicle.specifications
          .filter(item => item.title.includes('Totalvikt'))
          .map(spec => spec.value !== '0 kg' &&
            <VehicleObjectSpec
              key={'mobile-home-total-weight'}
              value={spec.value}
              icon={'balance-scale'} />
          )}

        {vehicle.layout !== null && <>
          <VehicleObjectSpec
            key={'mobile-home-layout'}
            value={transAny('filter.input.layouts.' + vehicle.layout.toLowerCase())}
            icon={'expand'} />
        </>}
      </>}
    </div>
  );
}