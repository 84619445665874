export const sv = {
  'filter.campaignOnly': 'Visa bara fordon med kampanjpris',
  'filter.noResult': 'Inga fordon kunde hittas',
  'filter.cat.alcove': 'Alkov',
  'filter.cat.double axle': 'Dubbelaxlad',
  'filter.cat.half integrated with roofbed': 'Halvintegrerad med takbädd',
  'filter.cat.half integrated': 'Halvintegrerad',
  'filter.cat.kombi': 'Kombi',
  'filter.cat.hatchback': 'Halvkombi',
  'filter.cat.integrated': 'Integrerad',
  'filter.cat.offroad': 'Terräng',
  'filter.cat.other': 'Annat',
  'filter.cat.single axle': 'Enkelaxlad',
  'filter.cat.snowmobile': 'Snöskoter',
  'filter.cat.sports coupe': 'Sportkupé',
  'filter.cat.van': 'Van',
  'filter.condition.all': 'Alla',
  'filter.condition.new': 'Nya',
  'filter.condition.used': 'Begagnade',
  'filter.list.all': 'Alla',
  'filter.list.over': 'Över {0} {1}',
  'filter.list.under': 'Under {0} {1}',
  'filter.extra.hide': 'Dölj fler filter',
  'filter.extra.show': 'Visa fler filter',
  'filter.input.amount.unit': 'st',
  'filter.input.bedsMax': 'Max sovplatser',
  'filter.input.brand': 'Märke',
  'filter.input.effect': 'Hästkrafter',
  'filter.input.effect.unit': 'hk',
  'filter.input.filial': 'Anläggning',
  'filter.input.gearbox': 'Växellåda',
  'filter.input.gearbox.automat': 'Automat',
  'filter.input.gearbox.manuell': 'Manuell',
  'filter.input.fuel': 'Bränsle',
  'filter.input.layouts': 'Planlösning',
  'filter.input.layouts.double_bed': 'Dubbelbädd',
  'filter.input.layouts.queenbed': 'Queen bed',
  'filter.input.layouts.kids_room': 'Barnkammare',
  'filter.input.layouts.long_beds': 'Långbäddar',
  'filter.input.layouts.other': 'Övrigt',
  'filter.input.lengthMax': 'Max längd',
  'filter.input.widthMax': 'Max bredd',
  'filter.input.heightMax': 'Max höjd',
  'filter.input.milageMax': 'Max antal mil',
  'filter.input.milageMax.unit': 'mil',
  'filter.input.passengersMax': 'Max färdplatser',
  'filter.input.price': 'Pris',
  'filter.input.price.unit': 'kr',
  'filter.input.weightMax': 'Max totalvikt',
  'filter.input.year': 'Modellår',
  'filter.input.heating': 'Värmesystem',
  'filter.reset': 'Nollställ filter',
  'filter.search': 'Sök',
  'filter.search.result': '{0} fordon matchar din sökning',
  'filter.search.showMore': 'Visa fler fordon',
  'filter.search.title': 'Sök med valda parametrar',
  'filter.searchPlaceholder': 'Vad letar du efter?',
  'filter.sort.created': 'Senaste',
  'filter.sort.priceAsc': 'Lägst pris',
  'filter.sort.priceDesc': 'Högst pris',
  'filter.sort.titleAsc': 'A till Ö',
  'filter.sort.yearDesc': 'Modellår',
  'filter.sort.mileageAsc': 'Miltal',
  'filter.sort.effectDesc': 'Effekt',
  'filter.sortResult': 'Sortera resultat',
  'filter.typeTitle': 'Välj fordonstyp',
  'object.action.buy': 'Köp online',
  'object.action.call': 'Ring oss',
  'object.action.interestForm': 'Intresseanmälan',
  'object.action.pdf': 'Skriv ut',
  'object.action.pdf.failed': 'Kunde inte skriva ut',
  'object.action.pdf.processing': 'Skriver ut...',
  'object.action.pdf.succeeded': 'Utskrivet! Sparar PDF.',
  'object.action.youTube': 'Visa film',
  'object.campaignPrice': 'Spara {0} kr',
  'object.campaignPriceTitle': 'Kampanjpris',
  'object.campaignTitle': 'Kampanj',
  'object.equipmentDesc': 'Detta fordon har extrautrustning för:',
  'object.equipmentTitle': 'Utrustning',
  'object.filialDesc': 'Fordonet finns på vår anläggning i',
  'object.descHeader': 'Beskrivning',
  'object.notFound': 'Objektet kunde inte hittas',
  'object.orgPrice': 'ord. pris',
  'object.price': '{0} kr',
  'object.priceExVat': 'ex. moms',
  'object.priceIncVat': 'inkl. moms',
  'object.priceDetails': 'inkl. utrustning',
  'object.pricePerMonth': 'Delbetala från {0} kr / mån',
  'object.similarTitle': 'Dessa fordon kanske också passar dig',
  'object.showMoreEquipment': 'Visa all utrustning',
  'object.showLessEquipment': 'Visa mindre utrustning',
  'object.panorama.header': '360-bild',
  'object.panorama.desc': 'Tryck och dra för att se fordonets interiör.',
  'object.specItem.fuel.gasoline': 'Bensin',
  'object.specItem.fuel.diesel': 'Diesel',
  'object.specItem.fuel.hybridGasoline': 'Hybrid el/bensin',
  'object.specItem.fuel.hybridDiesel': 'Hybrid el/diesel',
  'object.specItem.fuel.naturalGas': 'Naturgas',
  'object.specItem.fuel.electricity': 'El',
  'object.specItem.fuel.gasolineEthanol': 'Bensin/etanol',
  'object.specItem.fuel.gasolineGas': 'Bensin/gas',
  'object.specItem.axleType': 'Axeltyp',
  'object.specItem.beds': 'Sovplatser',
  'object.specItem.condition': 'Skick',
  'object.specItem.condition.new': 'Ny',
  'object.specItem.condition.used': 'Begagnad',
  'object.specItem.conditionlabel.new': 'Ny',
  'object.specItem.conditionlabel.used': 'Beg',
  'object.specItem.engineType': 'Motortyp',
  'object.specItem.gear': 'Växellåda',
  'object.specItem.gear.automatic': 'Automatisk',
  'object.specItem.gear.manual': 'Manuell',
  'object.specItem.gear.sequential': 'Sekventiell',
  'object.specItem.gearAuto': 'Automatisk',
  'object.specItem.gearManual': 'Manuell',
  'object.specItem.height': 'Höjd',
  'object.specItem.layout': 'Planlösning',
  'object.specItem.length': 'Längd',
  'object.specItem.mileage': 'Mätarställning',
  'object.specItem.milageUnit': 'Mil',
  'object.specItem.passengers': 'Färdplatser',
  'object.specItem.power': 'Effekt',
  'object.specItem.reg': 'Regnr',
  'object.specItem.articleNr': 'Artikelnr',
  'object.specItem.totalWeight': 'Totalvikt',
  'object.specItem.vehicleType': 'Fordonstyp',
  'object.specItem.width': 'Bredd',
  'object.specItem.workingWeight': 'Tjänstevikt',
  'object.specItem.year': 'Årsmodell',
  'object.specTitle.engineinfo': 'Motor och drivlina',
  'object.specTitle.measure': 'Mått och vikt',
  'object.specTitle.model': 'Utformning',
  'object.status.reserved': 'Reserverad',
  'object.status.sold': 'Såld',
  'object.type': 'Typ',
  'object.type.boat': 'Båt',
  'object.type.car': 'Bil',
  'object.type.caravan': 'Husvagn',
  'object.type.mcAtv': 'MC/ATV',
  'object.type.snowmobile': 'Snöskoter',
  'object.type.rv': 'Husbil',
  'object.type.mobileHome': 'Husbil',
  'object.type.trailer': 'Släpvagn',
  'object.type.transport': 'Transportfordon',
  'object.type.unknown': 'Okänt',
  'object.type.other': 'Övrigt',
  'object.goBack': 'Gå tillbaka',
  'object.files.header': 'Filer',
  'seller.desc': 'Kontakta mig för en provkörning eller mer information om fordonet.',
  'seller.title': 'Säljare',
  'payment.monthly': 'Dela upp betalningen',
  'payment.monthlyCost': '{0} kr / mån',
  'payment.downPayment.title': 'Kontantinsats',
  'payment.downPayment.value': '{0} kr',
  'payment.loanTime.title': 'Lånetid',
  'payment.loanTime.value': '{0} mån',
  'payment.calcInfo.title': 'Så har vi räknat',
  'payment.calcInfo.limit': 'Månadskostnaden får inte understiga {0} kr',
  'payment.calcInfo.downPayment': 'Kontantinsats (minst {0}%):',
  'payment.calcInfo.loan': 'Lån:',
  'payment.calcInfo.interestCost': 'Total räntekostnad:',
  'payment.calcInfo.total': 'Totalbelopp (exkl. avgifter):',
  'payment.calcInfo.summary': 'Med ett lån på {0} kr som ska betalas över {1} månader blir månadskostnaden {2} kr med en ränta på {3}%. Finansieringsförslaget är ett räkneexempel. Ev. inbyte räknas som kontantinsats. Kontakta oss för att få ett personligt upplägg.',
  'payment.calcSummary.summary': '{1} % egenkapital. {2} % nom. ränta / {3} % eff. ränta. <br/>Lånebelopp: {4} {0} i {5} mnd. <br/>Kreditkostnad: {6} {0}. Totalt: {7} {0}.',
  'payment.cash.title': 'Pris vid kontant betalning',
  'payment.cash.info': 'Fyll i uppgifterna i formuläret. När du har avslutat din beställning kontaktar vi dig.',
  'payment.default.currency': 'kr',
  'santander.error.timeout': 'Tid för signering har gått ut, försök igen.',
  'santander.status.pending': 'Signering behandlas av Santander. Kontrollerar status...',
  'santander.status.signed': 'Signering är klar och godkänd!.',
  'santander.status.error': 'Signering kunde inte göras och/eller har blivit avbruten.',
  'santander.status.mailSent': 'Ett mail har skickats med en länk till signering. Kontrollerar status...',
  'santander.status.submitting': 'Väntar på BankID. Verifiera med din enhet.',
  'santander.info': 'Santander Consumer Bank har lång erfarenhet inom fordonsfinansiering och har nära samarbeten med bil- och fritidshandare över hela landet. Vi är en del av den globala bankkoncernen Banco Santander, en av världens största banker.',
  'finance.status.error': 'Kunde inte göra en förfrågan. Försök igen senare eller kontakta din handlare.',
  'finance.status.signError': 'Kunde inte göra en förfrågan. Försök igen senare eller kontakta din handlare.',
  'finance.status.reserving': 'Reserverar fordon...',
  'finance.status.reserved': 'Tack för din beställning. Vi kommer att kontakta dig inom kort.',
  'finance.status.fetchingUrl': 'Hämtar signering...',
  'finance.buyer': 'Köpare',
  'finance.seller': 'Säljare',
  'finance.payment': 'Betalning',
  'finance.termInMonths': 'Kredittid',
  'finance.nominalInterest': 'Ränta',
  'finance.effectiveInterest': 'Effektiv ränta',
  'finance.financedAmount': 'Avbetalningsbelopp',
  'finance.downPayment': 'Kontantinsats',
  'finance.administrationFee': 'Administrationsavgift',
  'finance.arrangementFee': 'Uppläggningsavgift',
  'finance.unit.month': 'mån',
  'finance.unit.currency': 'kr',
  'finance.sign': 'Signera',
  'finance.order': 'Beställ',
  'finance.status.fetchingSign': 'Hämtar signering...',
  'finance.status.signing': 'Signering pågår i ny flik. Kontrollerar status...',
  'finance.status.signingInfo1': 'Klicka här',
  'finance.status.signingInfo2': 'om en ny flik i webbläsaren inte kunde öppnas.',
  'finance.party.name': 'Namn',
  'finance.party.pnr': 'Personnummer',
  'finance.party.email': 'E-postadress',
  'finance.party.phone': 'Telefon',
  'finance.form.infoHtml': 'Välj om du vill dela upp betalningen eller betala fordonet kontant. Ange dina uppgifter och skicka din förfrågan. Köpet är inte bindande och du betalar inget förrän du har haft kontakt med handlaren. <span>Om du valt finans innebär det att du ansöker om lånelöfte med Mobilt Bank ID och får svar direkt på skärmen.</span>',
  'finance.form.pnr': 'Personnummer',
  'finance.form.pnr.placeholder': 'ÅÅÅÅMMDDXXXX',
  'finance.form.firstname': 'Förnamn',
  'finance.form.lastname': 'Efternamn',
  'finance.form.address': 'Adress',
  'finance.form.message': 'Meddelande',
  'finance.form.address.placeholder': 'Gatunamnet 3',
  'finance.form.city': 'Ort',
  'finance.form.zipcode': 'Postnummer',
  'finance.form.zipcode.placeholder': '123 45',
  'finance.form.email': 'E-postadress',
  'finance.form.phone': 'Telefon',
  'finance.form.deliveryDate': 'Önskat leveransdatum',
  'finance.form.gdprLabel': 'Behandling av personuppgifter',
  'finance.form.gdprTerms': 'Jag har kontrollerat att uppgifterna ovan är korrekta och har tagit del av och godkänner behandlingen av personuppgifter. Läs mer om hur dina personuppgifter behandlas.',
  'finance.form.send': 'Verifiera med BankID',
  'finance.form.reserve': 'Beställ',
  'finance.verification.confirmed': 'Din låneansökan har blivit godkänd. Om du väljer att gå vidare med din beställning upprättas finansieringsavtal tillsammans med handlaren.',
  'checkout.title': 'Köp online',
  'checkout.bookingErrorHtml': '<span><strong><i class="fas fa-times"></i>Vi kunde inte ta emot din förfrågan.</strong> Vi beklagar detta. Kontrollera gärna att du har fyllt i dina uppgifter rätt och att du inte har missat något obligatoriskt fält. Du får gärna testa igen senare eller höra av dig direkt till oss.</span>',
  'checkout.bookingApprovedHtml': '<i class="fas fa-check"></i>Tack för din ansökan om finansiering. Vi kommer att kontakta dig så snart som möjligt.',
  'checkout.bookingTimeOutHtml': '<i class="fas fa-exclamation-circle"></i>Vi fick inte in något svar från BankID med det personnummer du angav. Vänligen försök igen eller kontakta oss.',
  'checkout.bookingComplete': 'Tack för din förfrågan! Du kommer inom kort bli kontaktad av oss för att slutföra köpprocessen.',
  'checkout.bookingFail': 'Vi kunde inte ta emot din förfrågan. Vi beklagar detta, men försök gärna igen eller kontakta oss direkt.',
  'checkout.closeWindow': 'Stäng fönstret',
  'checkout.payment.finance': 'Finansiering',
  'checkout.payment.cash': 'Kontant',
  'checkout.ecster.verifying': 'Väntar på BankID. Verifiera med din enhet.',
  'checkout.ecster.submitButton': 'Ansök och beställ',
  'checkout.ecster.cashDesc': 'Välj om du vill dela upp betalningen eller betala fordonet kontant. Ange dina uppgifter och skicka din förfrågan. Köpet är inte bindande och du betalar inget förrän du har haft kontakt med handlaren.',
  'checkout.ecster.financeDesc': 'Vill du dela upp betalningen fyller du i dina uppgifter och skickar in din ansökan med BankID. Du får svar direkt och kan reservera det objekt du är intresserad av. Köpet slutför du sedan tillsammans med handlaren.',
  'checkout.ecster.financeTermsHtml': '<p>Jag godkänner <a href="https://www.ecster.se/globalassets/content/shared/documents/terms/villkor-lanelofte.pdf" target="_blank">allmänna villkor lånelöfte</a>.</p>',
  'checkout.ecster.applying': 'Behandlar din bokning.',
  'checkout.ecster.bookingApprovedHtml': 'Din ansökan är godkänd och vi kontaktar dig så fort som möjligt kring slutförandet av köpet.',
  'checkout.ecster.bookingPendingHtml': 'Tack för din ansökan<br/><br/>Din ansökan kommer att behandlas av en handläggare hos Ecster och vi återkommer så snart som möjligt. Om ditt ärende brådskar eller om du har frågor, vänligen ring oss.<br/><br/>Telefonnummer: 08-33 01 30<br/>Öppettider: vardagar 9-18<br/><br/>Vänliga hälsningar<br/>Ecster',
  'checkout.ecster.bookingDeniedHtml': 'Tack för din ansökan<br/><br/>Vi kan tyvärr inte godkänna din ansökan. Om du har frågor är du varmt välkommen att ringa oss.<br/><br/>Telefonnummer: 08-33 01 30<br/>Öppettider: vardagar 9-18<br/><br/>Vänliga hälsningar<br/>Ecster',
  'form.interest.title': 'Intresseanmälan',
  'form.interest.submitted': 'Tack för din anmälan!',
  'form.interest.policy': 'Jag godkänner att {0} behandlar mina uppgifter enligt integritetspolicyn.',
  'form.interest.policyWarning': 'Du måste godkänna att {0} behandlar dina uppgifter för att kunna göra en intresseanmälan.',
  'form.interest.policyWarningChoise': 'Du måste välja minst ett alternativ på hur {0} ska nå dig!',
  'form.interest.infoHtml': 'Är du intresserad av att veta mer om det här fordonet?<br/>Fyll i fina uppgifter nedan så kontaktar vi dig!',
  'form.interest.contactEmail': 'Kontakta mig via e-post',
  'form.interest.contactPhone': 'Kontakta mig via telefon',
  'form.interest.name': 'Namn',
  'form.interest.similar': 'Av liknande objekt',
  'form.interest.send': 'Skicka',
  'form.interest.error.mandatory': 'Obligatorisk',
  'form.interest.error.email': 'Ogiltig e-postadress',
  'share.main': 'Dela',
  'share.facebook': 'Dela på Facebook',
  'share.messenger': 'Dela på Messenger',
  'share.mail': 'Dela via mail',
  'share.mail.subject': 'Har du sett detta fordon?',
  'share.copy': 'Kopiera länk',
  'share.copy.copied': 'Länk kopierad till urklipp!',
};
