import * as query from '../../query';
import * as core from '../../model/core';
import { stringListSeparator } from '../../util';
import { ObjectFilterViewProps, ObjectFilterViewState } from './objectFilterView';
import { sortOrderToString } from './sorting';

export const queryStringSchema: query.QueryStringSchema<core.ObjectQueryParameters> = {
  type:             query.number(),
  groups:           query.string(),
  filials:          query.string(),
  brands:           query.string(),
  campaignOnly:     query.bool(),
  categories:       query.string(),
  max:              query.number(),
  offset:           query.number(),
  priceFrom:        query.number(),
  priceTo:          query.number(),
  searchText:       query.string(),
  sortOrder:        query.string(),
  weightMax:        query.number(),
  selectedObjects:  query.string(),
  yearFrom:         query.number(),
  yearTo:           query.number(),
  condition:        query.number(),

  // Extra
  bedsMax:          query.number(),
  layouts:          query.string(),
  lengthMax:        query.number(),
  widthMax:         query.number(),
  heightMax:        query.number(),
  passengersMax:    query.number(),
  gearbox:          query.string(),
  fuel:             query.string(),
  milageMax:        query.number(),
  effectFrom:       query.number(),
  effectTo:         query.number(),
  heating:          query.string(),
  hideSold:         query.bool(),
};

export function setGroupQuery(groups: string[], queryParams: Partial<core.ObjectQueryParameters>) {
  queryParams.groups = groups.join(stringListSeparator);
}

export function defaultQueryParams(
  props: ObjectFilterViewProps,
  state?: ObjectFilterViewState): Partial<core.ObjectQueryParameters> {

  const queryParams: Partial<core.ObjectQueryParameters> = {
    searchText: '',
    sortOrder: sortOrderToString({
      order: core.ObjectOrder.created
    }),
    categories: '',
    condition: core.Condition.all
  };

  if (state) {
    queryParams.type = state.filterData?.types[0]?.type;
  }

  if (props.settings) {
    const { groups } = props.settings;

    if (groups && groups.length) {
      setGroupQuery(groups, queryParams);
    }
  }

  return queryParams;
}