import * as core from '../model/core';
import { objectToQueryString } from '../query';
import { Endpoint } from './endpoint';

export const endpoint = new Endpoint('/dsoApi/v1');
const { get, post, getBlob } = endpoint;

export async function getFilterData(query: Partial<core.FilterQueryParameters>) {
  const queryString = objectToQueryString(query);
  return await get<core.FilterData | null>(`filterData${queryString}`);
}

export async function getObjects(query: Partial<core.ObjectQueryParameters>) {
  const queryString = objectToQueryString(query);
  return await get<core.ObjectQueryResult>(`objects${queryString}`);
}

export async function getObject(uniqueId: string) {
  return await get<core.VehicleExtended | null>(`object/${uniqueId}`);
}

export async function getGeneralConfig() {
  return await get<core.GeneralConfig>('generalconfig');
}

export async function postInterest(form: Partial<core.InterestForm>) {
  return await post<boolean>('interest', form);
}

export async function mailFavourites(favourites: core.Favourites) {
  return await post<boolean>('favourites', favourites);
}

export async function createCashBooking(booking: core.CreateCashBooking) {
  return await post<boolean>('cashbooking', booking);
}
export async function printToPdf(print: core.PrintParameters) {
  return await getBlob('print', 'application/pdf', print);
}

export async function getEffectiveRate(query: core.EffectiveRateQueryParameters) {
  const queryString = objectToQueryString(query);
  return await get<number>(`effectiveRate${queryString}`);
}