export const en = {
  'filter.campaignOnly': 'Only vehicles with discount',
  'filter.noResult': 'No vehicles could be found',
  'filter.cat.alcove': 'Alcove',
  'filter.cat.double axle': 'Double axle',
  'filter.cat.half integrated with roofbed': 'Half integrated with roofbed',
  'filter.cat.half integrated': 'Half integrated',
  'filter.cat.hatchback': 'Hatchback',
  'filter.cat.integrated': 'Integrated',
  'filter.cat.offroad': 'Terrain',
  'filter.cat.other': 'Other',
  'filter.cat.single axle': 'Single axle',
  'filter.cat.snowmobile': 'Snowmobile',
  'filter.cat.sports coupe': 'Coupe',
  'filter.cat.van': 'Van',
  'filter.condition.all': 'All',
  'filter.condition.new': 'New',
  'filter.condition.used': 'Used',
  'filter.extra.hide': 'Hide extra filter options',
  'filter.extra.show': 'Show extra filter options',
  'filter.input.amount.unit': 'st',
  'filter.input.bedsMax': 'Beds max',
  'filter.input.brand': 'Brand',
  'filter.input.effect': 'Horsepower',
  'filter.input.filial': 'Location',
  'filter.input.gearbox': 'Gearbox',
  'filter.input.gearbox.automat': 'Automatic',
  'filter.input.gearbox.manuell': 'Manual',
  'filter.input.layouts': 'Layout',
  'filter.input.lengthMax': 'Length max',
  'filter.input.widthMax': 'Width max',
  'filter.input.heightMax': 'Height max',
  'filter.input.milageMax': 'Milage max',
  'filter.input.passengersMax': 'Passengers max',
  'filter.input.price': 'Price',
  'filter.input.price.unit': 'kr',
  'filter.input.weightMax': 'Total weight max',
  'filter.input.year': 'Year',
  'filter.reset': 'Reset filter',
  'filter.search': 'Search',
  'filter.search.result': '{0} matches your search',
  'filter.search.showMore': 'Show more vehicles',
  'filter.search.title': 'Search with given parameters',
  'filter.searchPlaceholder': 'What are you looking for?',
  'filter.sort.created': 'Latest',
  'filter.sort.priceAsc': 'Lowest price',
  'filter.sort.priceDesc': 'Highest price',
  'filter.sort.titleAsc': 'A to Z',
  'filter.sortResult': 'Sort result',
  'filter.typeTitle': 'Choose vehicle type',
  'object.action.buy': 'Buy online',
  'object.action.call': 'Call us',
  'object.action.interestForm': 'Interest form',
  'object.action.youTube': 'Show video',
  'object.campaignPrice': 'Save {0} kr',
  'object.campaignPriceTitle': 'Discount',
  'object.campaignTitle': 'Discount',
  'object.equipmentDesc': 'This vehicle is equiped with factory accessories for:',
  'object.equipmentTitle': 'Equipment',
  'object.filialDesc': 'This vehicle is located at our facility in',
  'object.descHeader': 'Description',
  'object.notFound': 'Object could not be found',
  'object.orgPrice': 'org. price',
  'object.price': '{0} kr',
  'object.priceDetails': 'incl. equipment',
  'object.pricePerMonth': 'Payment from {0} kr / month',
  'object.similarTitle': 'These vehicles might also be of your interest',
  'object.specItem.fuel.gasoline': 'Gasoline',
  'object.specItem.fuel.diesel': 'Diesel',
  'object.specItem.fuel.hybridGasoline': 'Hybrid electricity/gasoline',
  'object.specItem.fuel.hybridDiesel': 'Hybrid electricity/diesel',
  'object.specItem.fuel.naturalGas': 'Natural gas',
  'object.specItem.fuel.electricity': 'Electricity',
  'object.specItem.fuel.gasolineEthanol': 'Gasoline/Ethanol',
  'object.specItem.fuel.gasolineGas': 'Gasoline/Gas',
  'object.specItem.axleType': 'Axle type',
  'object.specItem.beds': 'Beds',
  'object.specItem.condition': 'Condition',
  'object.specItem.condition.new': 'New',
  'object.specItem.condition.used': 'Used',
  'object.specItem.conditionlabel.new': 'New',
  'object.specItem.conditionlabel.used': 'Used',
  'object.specItem.engineType': 'Engine type',
  'object.specItem.gear': 'Gearbox',
  'object.specItem.gearAuto': 'Automatic',
  'object.specItem.gearManual': 'Manual',
  'object.specItem.height': 'Height',
  'object.specItem.layout': 'Layout',
  'object.specItem.length': 'Length',
  'object.specItem.mileage': 'Mileage',
  'object.specItem.milageUnit': 'Mil',
  'object.specItem.passengers': 'Passengers',
  'object.specItem.power': 'Horsepower',
  'object.specItem.reg': 'Registration',
  'object.specItem.articleNr': 'Article no.',
  'object.specItem.totalWeight': 'Total weight',
  'object.specItem.vehicleType': 'Vehicle type',
  'object.specItem.width': 'Width',
  'object.specItem.workingWeight': 'Kerb weight',
  'object.specItem.year': 'Year',
  'object.specTitle.engineinfo': 'Engine details',
  'object.specTitle.measure': 'Measurements',
  'object.specTitle.model': 'Model',
  'object.status.reserved': 'Reserved',
  'object.status.sold': 'Sold',
  'object.type.boat': 'Boat',
  'object.type.car': 'Car',
  'object.type.caravan': 'Caravan',
  'object.type.mcAtv': 'MC/ATV',
  'object.type.rv': 'RV',
  'object.type.mobileHome': 'RV',
  'object.type.trailer': 'Trailer',
  'object.type.transport': 'Transportation',
  'seller.desc': 'Contact me for a test drive or more information about the vehicle.',
  'seller.title': 'Seller',
  'payment.monthly': 'Dela upp betalningen',
  'payment.monthlyCost': '{0} kr / mån',
  'payment.downPayment.title': 'Down payment',
  'payment.downPayment.value': '{0} kr',
  'payment.loanTime.title': 'Loan time',
  'payment.loanTime.value': '{0} mån',
  'payment.calcInfo.title': 'Så har vi räknat',
  'payment.calcInfo.limit': 'Månadskostnaden får inte understiga {0} kr',
  'payment.calcInfo.downPayment': 'Kontantinsats (minst {0}%):',
  'payment.calcInfo.loan': 'Lån:',
  'payment.calcInfo.interestCost': 'Total räntekostnad:',
  'payment.calcInfo.total': 'Totalbelopp (exkl. avgifter):',
  'payment.calcInfo.summary': 'Med ett lån på {0} kr som ska betalas över {1} månader blir månadskostnaden {2} kr med en ränta på {3}%. Finansieringsförslaget är ett räkneexempel. Ev. inbyte räknas som kontantinsats. Kontakta oss för att få ett personligt upplägg.',
  'payment.calcSummary.summary': '{1} % egenkapital. {2} % nom. rente / {3} % eff. rente. <br/>Lånebeløp: {4} {0} i {5} mnd. <br/>Kredittkostnad: {6} {0}. Totalt: {7} {0}.',
  'payment.default.currency': 'EUR',
  'payment.cash.title': 'Pris vid kontant betalning',
  'payment.cash.info': 'Fyll i uppgifterna i formuläret. När du har avslutat din beställning kontaktar vi dig.',
  'santander.error.timeout': 'Tid för signering har gått ut, försök igen.',
  'santander.status.pending': 'Signering behandlas av Santander. Kontrollerar status...',
  'santander.status.signed': 'Signering är klar och godkänd!.',
  'santander.status.error': 'Signering kunde inte göras och/eller har blivit avbruten.',
  'santander.status.mailSent': 'Ett mail har skickats med en länk till signering. Kontrollerar status...',
  'santander.status.submitting': 'Väntar på BankID. Verifiera med din enhet.',
  'santander.info': 'Santander Consumer Bank har lång erfarenhet inom fordonsfinansiering och har nära samarbeten med bil- och fritidshandare över hela landet. Vi är en del av den globala bankkoncernen Banco Santander, en av världens största banker.',
  'finance.status.error': 'Kunde inte göra en förfrågan. Försök igen senare eller kontakta din handlare.',
  'finance.status.signError': 'Kunde inte göra en förfrågan. Försök igen senare eller kontakta din handlare.',
  'finance.buyer': 'Köpare',
  'finance.seller': 'Säljare',
  'finance.payment': 'Betalning',
  'finance.termInMonths': 'Kredittid',
  'finance.nominalInterest': 'Ränta',
  'finance.effectiveInterest': 'Effektiv ränta',
  'finance.financedAmount': 'Avbetalningsbelopp',
  'finance.downPayment': 'Kontantinsats',
  'finance.administrationFee': 'Administrationsavgift',
  'finance.arrangementFee': 'Uppläggningsavgift',
  'finance.unit.month': 'mån',
  'finance.unit.currency': 'kr',
  'finance.sign': 'Signera',
  'finance.order': 'Beställ',
  'finance.status.fetchingSign': 'Hämtar signering...',
  'finance.status.signing': 'Signering pågår i ny flik. Kontrollerar status...',
  'finance.status.signingInfo1': 'Klicka här',
  'finance.status.signingInfo2': 'om en ny flik i webbläsaren inte kunde öppnas.',
  'finance.party.name': 'Namn',
  'finance.party.pnr': 'Personnummer',
  'finance.party.email': 'E-postadress',
  'finance.party.phone': 'Telefon',
  'finance.form.infoHtml': 'Välj om du vill dela upp betalningen eller betala fordonet kontant. Ange dina uppgifter och skicka din förfrågan. Köpet är inte bindande och du betalar inget förrän du har haft kontakt med handlaren. <span>Om du valt finans innebär det att du ansöker om lånelöfte med Mobilt Bank ID och får svar direkt på skärmen.</span>',
  'finance.form.pnr': 'Personnummer',
  'finance.form.pnr.placeholder': 'ÅÅÅÅMMDDXXXX',
  'finance.form.firstname': 'Förnamn',
  'finance.form.lastname': 'Efternamn',
  'finance.form.address': 'Adress',
  'finance.form.address.placeholder': 'Gatunamnet 3',
  'finance.form.city': 'Ort',
  'finance.form.zipcode': 'Postnummer',
  'finance.form.zipcode.placeholder': '123 45',
  'finance.form.email': 'E-postadress',
  'finance.form.phone': 'Telefon',
  'finance.form.deliveryDate': 'Önskat leveransdatum',
  'finance.form.gdprLabel': 'Behandling av personuppgifter',
  'finance.form.gdprTerms': 'Jag har kontrollerat att uppgifterna ovan är korrekta och har tagit del av och godkänner behandlingen av personuppgifter. Läs mer om hur dina personuppgifter behandlas.',
  'finance.form.send': 'Verifiera med BankID',
  'finance.form.reserve': 'Beställ',
  'checkout.title': 'Köp online',
  'checkout.bookingErrorHtml': '<span><strong><i class="fas fa-times"></i>Vi kunde inte ta emot din förfrågan.</strong> Vi beklagar detta. Kontrollera gärna att du har fyllt i dina uppgifter rätt och att du inte har missat något obligatoriskt fält. Du får gärna testa igen senare eller höra av dig direkt till oss.</span>',
  'checkout.bookingApprovedHtml': '<i class="fas fa-check"></i>Tack för din ansökan om finansiering. Vi kommer att kontakta dig så snart som möjligt.',
  'checkout.bookingTimeOutHtml': '<i class="fas fa-exclamation-circle"></i>Vi fick inte in något svar från BankID med det personnummer du angav. Vänligen försök igen eller kontakta oss.',
  'checkout.bookingComplete': 'Tack för din förfrågan! Du kommer inom kort bli kontaktad av oss för att slutföra köpprocessen.',
  'checkout.bookingFail': 'Vi kunde inte ta emot din förfrågan. Vi beklagar detta, men försök gärna igen eller kontakta oss direkt.',
  'checkout.closeWindow': 'Stäng fönstret',
  'checkout.payment.finance': 'Finansiering',
  'checkout.payment.cash': 'Kontant',
  'form.interest.title': 'Intresseanmälan',
  'form.interest.submitted': 'Tack för din anmälan!',
  'form.interest.policy': 'Jag godkänner att {0} behandlar mina uppgifter enligt integritetspolicyn.',
  'form.interest.policyWarning': 'Du måste godkänna att {0} behandlar dina uppgifter för att kunna göra en intresseanmälan.',
  'form.interest.policyWarningChoise': 'Du måste välja minst ett alternativ på hur {0} ska nå dig!',
  'form.interest.infoHtml': 'Är du intresserad av att veta mer om det här fordonet?<br/>Fyll i fina uppgifter nedan så kontaktar vi dig!',
  'form.interest.contactEmail': 'Kontakta mig via e-post',
  'form.interest.contactPhone': 'Kontakta mig via telefon',
  'form.interest.name': 'Namn',
  'form.interest.similar': 'Av liknande objekt',
};
