import * as React from 'react';
import * as core from '../../../model/core';
import { translate as trans } from '../../../lang';
import { baseUrl } from '../../../util';

interface SellerProps {
  seller: core.Seller;
}

export function Seller({ seller }: SellerProps) {
  const sellerImage = `${baseUrl}/i/${(seller.image !== '') ? seller.image : 'blank'}?mw=300&mh=300&sf=1`;
  const phoneNumber = `tel:${seller.phoneNumber}`;
  const email = `mailto:${seller.email}`;
  return (
    <div className='dso-object-seller'>
      <div className='dso-object-seller-image' style={{backgroundImage: `url(${sellerImage})`}}></div>
      <div className='dso-object-seller-name'>
        <div className='dso-object-seller-name-centerer'>{seller.name} - {(seller.title === null || seller.title === '') ? trans('seller.title') : seller.title }</div>
      </div>
      <div className='dso-object-seller-desc'>{trans('seller.desc')}</div>
      <div className='dso-object-seller-contact-info'>
        <a href={phoneNumber}>
          {/* <i className='fa fa-phone'></i> */}
          {seller.phoneNumber}</a>
      </div>
      <div className='dso-object-seller-contact-mail'>
        <a href={email}>
          {/* <i className='fa fa-envelope'></i> */}
          {seller.email}</a>
      </div>
    </div>
  );
}