import * as React from 'react';
import * as core from '../../model/core';
import { className } from '../../util';
import { translate, translateAny } from '../../lang';

interface Props {
  allTypes: core.FilterDataType[];
  selectedType: core.VehicleType;
  select: (type: core.VehicleType) => void;
}

export function TypeSelection(props: Props) {
  const handleClick = (t: core.FilterDataType) => {
    return () => {
      props.select(t.type);
    };
  };

  return (
    <div className='dso-single-selection dso-vehicle-type-selection'>
      <span>{translate('filter.typeTitle')}</span>
      {props.allTypes.map(type =>
        <TypeItem
          key={type.name}
          name={translateAny('object.type.' + type.name)}
          click={handleClick(type)}
          selected={props.selectedType === type.type} />)}
    </div>
  );
}

interface TypeItemProps {
  selected: boolean;
  name: string;
  click: () => void;
}

function TypeItem(props: TypeItemProps) {
  const cls = className('dso-single-selection-item dso-vehicle-type-item', { 'dso-selected': props.selected });

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    props.click();
  };

  return (
    <a href='#' onClick={handleClick} className={cls}>{props.name}</a>
  );
}