import * as React from 'react';
import * as core from './model/core';
import * as api from './api/vehicleFilterApi';
import { translate as trans, translateFormat } from './lang';
import { formatNumber, className } from './util';
import { PrintVehicles } from './view/object/printVehicles';

export const vStorageStr = 'dsoFavourites';
const windowWidth = window.innerWidth;
// eslint-disable-next-line max-len
const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

// Rework this boolean after 2021-09-08 (Autohallen has exclusivity until then)
export const useFavourites = window.location.origin.includes('autohallen');

function AddStorageVehicle (addId: string) {
  // Get current localStorage string
  let currentStorage = localStorage.getItem(vStorageStr) === null ? '' : localStorage.getItem(vStorageStr);

  // Combine the strings to localStorage
  if (!localStorage.getItem(vStorageStr)?.includes(addId)) {
    localStorage.setItem(vStorageStr, addId + ',' + currentStorage);
  }
};

function RemoveStorageVehicle (removeId: string) {

  // Get current localStorage
  let currentStorage = localStorage
    .getItem(vStorageStr)
    ?.split(',')
    .filter(item => (item !== removeId));

  // Create a new string for localStorage, without the id that has been removed above
  let newStorage = currentStorage?.join(',');
  localStorage.setItem(vStorageStr, newStorage!);

};

interface ManageFavouritesProps {
  objectId: string;
  storageVehicles?: string | null;
  updateStorage: (value: string) => void;
  inline?: boolean;
}

export class ManageVehicleStorage extends React.Component<ManageFavouritesProps,{}> {

  constructor(props: ManageFavouritesProps) {
    super(props);
  }

  ManageVehicle(e: any, id: string) {
    e.preventDefault();
    if (localStorage.getItem(vStorageStr)?.includes(id)) {
      RemoveStorageVehicle(id);
      this.props.updateStorage(localStorage.getItem(vStorageStr)!);
    } else {
      AddStorageVehicle(id);
      this.props.updateStorage(localStorage.getItem(vStorageStr)!);
    }
  }

  render() {
    const inStorage = this.props.storageVehicles?.includes(this.props.objectId);
    return(
      <div className={className('dso-manage-favourites', {inline: this.props.inline})}
        onClick={(e) => this.ManageVehicle(e, this.props.objectId)}>
        {inStorage
          ?<>
            <div className='dso-manage-fav-btn saved'>
              <i className='fas fa-check'></i> Sparad som favorit
            </div>
          </>:<>
            <div className='dso-manage-fav-btn'>
              <i className='far fa-star'></i> Spara som favorit
            </div>
          </>}
      </div>
    );
  };
}

interface FavouritesProps {
  storageVehicles: string | null;
  updateStorage: (value: string) => void;
  config: core.GeneralConfig;
}

interface FavouritesState {
  toggled: boolean;
  vehicles: core.VehicleExtended[];
  email: string;
  emailSending: boolean;
  emailFailed: boolean;
  emailSent: boolean;
}

export class Favourites extends React.Component<FavouritesProps, FavouritesState> {

  constructor(props: FavouritesProps) {
    super(props);
    this.state = {
      toggled: false,
      vehicles: [],
      email: '',
      emailSending: false,
      emailFailed: false,
      emailSent: false
    };
  }

  async sendToMail() {
    if (emailRegex.test(this.state.email) && this.state.email.length > 4 && this.state.vehicles.length > 0) {
      const mail = {email: this.state.email, vehicles: this.state.vehicles};
      this.setState({
        emailSending: true
      });

      try {
        await api.mailFavourites(mail as core.Favourites);
        this.setState({
          emailSent: true,
          emailSending: false
        });
      } catch (err) {
        this.setState({
          emailFailed: true,
          emailSending: false
        });
      }

    } else {
      this.setState({
        emailFailed: true,
        emailSending: false
      });
    }
  };

  clearVehicleStorage() {
    const ok = window.confirm('Är du säker på att du vill rensa dina favoriter?');

    if (ok) {
      this.setState({ vehicles: [] });
      localStorage.removeItem(vStorageStr);
      window.location.reload();
    }
  }

  async getObjects() {
    const favouriteObjects = this.props.storageVehicles
      ?.split(',')
      .filter(item => (item !== ''))
      .map(async item => {
        const object = await api.getObject(item);
        this.setState({
          vehicles: [object as core.VehicleExtended, ...this.state.vehicles]
        });
      });
  }

  async triggerWindow() {
    this.setState({ vehicles: [] });
    this.getObjects();
    this.setState({ toggled: true });
    if (windowWidth < 600 ) {
      document.body.style.overflowY = 'hidden';
    }
  }

  untriggerWindow(e: any) {
    if (e.target.title === 'Favoriter' ||
    (e.target.title === 'Mina favoriter' && this.state.toggled) ||
    e.target.title === 'Stäng') {
      this.setState({ vehicles: [] });
      this.setState({ toggled: false });

      if (windowWidth < 600 ) {
        document.body.style.overflowY = 'auto';
      }
    }
  }

  removeSingleVehicle(e: any, removeId: string) {
    e.preventDefault();
    RemoveStorageVehicle(removeId);
    this.props.updateStorage(localStorage.getItem(vStorageStr)!);
    this.setState({ vehicles: [] });
    this.setState({
      vehicles: this.state.vehicles.filter(item => item.uniqueId !== removeId )
    });
  }

  render() {
    return(
      <div className={className('dso-favourites-container', {toggled: this.state.toggled})}
        onClick={this.state.toggled ? (e) => this.untriggerWindow(e) : () => this.triggerWindow() } title={'Favoriter'}>
        <div className='dso-favourites-window'>
          {this.props.storageVehicles && <>
            <div className='dso-favourites-button' title={'Mina favoriter'}>
              <span>Mina favoriter <i className='far fa-star'></i></span>
            </div>
            <div className='dso-favourites-content'>
              <div className='dso-favourites-vehicles'>
                {this.state.vehicles &&
                  this.state.vehicles.map(item =>
                    <a className='vehicle-item' href={item.pageLink} key={item.uniqueId}>
                      <div className='vehicle-item-image'
                        style={{backgroundImage: 'url(' + item.images[0] + ')'}}></div>
                      <div className='vehicle-item-info'>
                        <strong>{item.title}</strong>
                        {item.year &&
                          <span>
                            <i className='fas fa-calendar-alt' aria-hidden="true"></i>
                            {item.year}
                          </span>}
                        {item.price &&
                          <span>
                            <i className='fas fa-coins' aria-hidden="true"></i>
                            {translateFormat('object.price', formatNumber(item.price))}
                          </span>}
                        {item.regNr &&
                          <span className='highlight'>
                            <i className='fas fa-tag' aria-hidden="true"></i>
                            {item.regNr}
                          </span>}
                      </div>
                      <div className='vehicle-remove-item'
                        onClick={(e) => this.removeSingleVehicle(e, item.uniqueId)}>
                        <i className='fa fa-trash'></i>
                      </div>
                    </a>
                  )}
              </div>
              <div className='dso-favourites-toolbar'>

                {!this.state.emailSending && !this.state.emailSent && !this.state.emailFailed && <>
                  <span className='fav-desc'>
                    Skriv in din e-postadress så skickar vi informationen om dina favoriter till dig,
                     så du kan bli bättre förberedd inför ditt besök hos oss!
                  </span>
                  <div className='fav-btn email'>
                    <i className='fas fa-envelope'></i>
                    <input
                      ref={input => input && input.focus()}
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value, emailFailed: false })}
                      placeholder={'Din e-postadress'}
                      type='email' />
                  </div>
                  <div className={className('fav-btn send', {canSend: emailRegex.test(this.state.email)})}
                    onClick={() => this.sendToMail()}>
                    <i className='fas fa-paper-plane'></i> Skicka till e-post</div></>}

                {this.state.emailSending && !this.state.emailFailed && !this.state.emailSent && <>
                  <span className='fav-desc'>Skickar e-post...</span></>}

                {!this.state.emailSending && !this.state.emailSent && this.state.emailFailed && <>
                  <span className='fav-desc'>Kunde inte skicka epost.
                   Vänligen kontakta oss direkt eller testa igen.</span></>}

                {!this.state.emailSending && !this.state.emailFailed && this.state.emailSent && <>
                  <span className='fav-desc'>Tack! Vi har nu skickat information
                   om dina favoritfordon till {this.state.email}.</span></>}

                {/* <div className='fav-btn print' onClick={() => PrintVehicles(this.state.vehicles, this.props.config!)} title={'Stäng'}>
                  <i className='fas fa-file-pdf'></i> {trans('object.action.pdf')}</div> */}
                <div className='fav-btn closewin' onClick={(e) => this.untriggerWindow(e)} title={'Stäng'}>
                  <i className='fas fa-times'></i> Stäng fönstret</div>
                <div className='fav-btn clear' onClick={() => this.clearVehicleStorage()}>
                  <i className='fa fa-trash'></i> Rensa favoriter</div>
              </div>
            </div>
          </>}
        </div>
      </div>
    );
  }
}