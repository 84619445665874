import * as React from 'react';
import { InterestInput, DsoCheckbox, ActionButton } from './input';
import * as core from '../../../model/core';
import * as api from '../../../api/vehicleFilterApi';
import { Warning } from '../../../warning';
import { Seller } from './seller';
import { autobind } from '../../../decorator';
import * as validation from './validation';
import { translate as trans, translateFormat as transFormat } from '../../../lang';
import * as gtm from '../../../gtm';

interface InterestFormSimilarObjectsProps {
  vehicle: core.VehicleExtended;
}

interface InterestFormSimilarObjectsState {
  showIntegrityModal: boolean;
  integrityPolicy: boolean;
  interestForm: Partial<core.InterestForm>;
  interestFormValidation: Partial<core.InterestForm>;
  formPosted: boolean;
}

export class InterestFormSimilarObjects extends React.Component<InterestFormSimilarObjectsProps, InterestFormSimilarObjectsState> {

  public element: HTMLElement | null = null;

  constructor(props: InterestFormSimilarObjectsProps) {
    super(props);

    this.state = {
      showIntegrityModal: false,
      integrityPolicy: false,
      interestForm: {
        email: '',
        phone: '',
        uniqueId: props.vehicle.uniqueId
      },
      interestFormValidation: {},
      formPosted: false
    };
  }

  async validateFields() {
    const schema = validation.interestFormSchemaAll;

    const { interestForm, integrityPolicy } = this.state;
    const { result, errorThrown } = validation.validateFields(interestForm, schema);

    this.setState({ interestFormValidation: result });

    if (integrityPolicy && !errorThrown) {
      const formPosted = await this.postForm();

      if (formPosted) {
        gtm.interestFormSubmit();
      }

      this.setState({ formPosted });
    } else {
      this.setState({ showIntegrityModal: !integrityPolicy });
    }
  }

  @autobind
  async postForm() {
    return await api.postInterest(this.state.interestForm);
  }

  @autobind
  handleChange(key: keyof core.InterestForm) {
    return (text: string) => {
      this.setState({
        interestForm: {
          ...this.state.interestForm,
          [key]: text
        }
      });
      this.setState({
        interestFormValidation: {
          ...this.state.interestFormValidation,
          [key]: ''
        }
      });
    };
  }

  render() {
    const { vehicle } = this.props;
    const {
      interestForm,
      interestFormValidation,
      integrityPolicy,
      showIntegrityModal,
      formPosted
    } = this.state;

    if (formPosted) {
      return (
        <div className='dso-object-notification-of-interest-container'>
          <h2>{trans('form.interest.title')}</h2>
          <div className='dso-object-notification-of-interest-data-container'>{trans('form.interest.submitted')}</div>
        </div>
      );
    }

    return (
      <div className='dso-object-notification-of-interest-similar-container' ref={elm => this.element = elm}>
        <h2>{trans('form.interest.title')}</h2>
        <p>{trans('form.interest.similar')}</p>

        <div className='dso-object-notification-of-interest-similar-data-container'>

          <div className='dso-object-notification-of-interest-similar-customer-data-row'>
            <InterestInput
              name={trans('finance.form.phone')}
              change={this.handleChange('phone')}
              error={interestFormValidation.phone}
              value={interestForm.phone!}
              cssClass='dso-input-container'
              autoComplete='tel'
              visible={true} />
          </div>

          <div className='dso-object-notification-of-interest-similar-customer-data-row'>
            <InterestInput
              name={trans('finance.form.email')}
              change={this.handleChange('email')}
              error={interestFormValidation.email}
              value={interestForm.email!}
              cssClass='dso-input-container'
              autoComplete='email'
              visible={true} />
          </div>

          <div className='dso-object-notification-of-interest-similar-customer-accept-terms'>
            <DsoCheckbox
              toggle={() => this.setState({ integrityPolicy: !integrityPolicy })}
              checked={this.state.integrityPolicy}
              cssClass='checkbox'
              id='integrityPolicy'>

              {transFormat('form.interest.policy', vehicle.dealershipName)}
            </DsoCheckbox>
          </div>

          <div className='dso-object-notification-of-interest-similar-send-button'>
            <ActionButton
              label={trans('form.interest.send')}
              icon='paper-plane'
              click={() => this.validateFields()} />

            <Warning
              visible={showIntegrityModal}
              onClose={() => this.setState({ showIntegrityModal: false })}>
              {transFormat('form.interest.policyWarning', vehicle.dealershipName)}
            </Warning>
          </div>
        </div>
      </div>
    );
  }
}
