import * as schema from './schema';

export * from './schema';

export type ResultObject<T> = { [key: string]: T };

export function queryStringToObject<T = ResultObject<string>>(
  queryString: string,
  schemaObject?: schema.QueryStringSchema<T>): Partial<T> {

  const qs = queryString?.replace(/^\?+/g, '') ?? '';
  const res: ResultObject<{}> = {};

  for (let kv of qs.split('&')) {
    let [key, value] = kv.split('=');

    key = key ? decodeURIComponent(key.trim()) : '';

    if (!key) {
      continue;
    }

    value = value ? decodeURIComponent(value) : '';

    const schemaObj = schemaObject
      ? (schemaObject as any)[key] as schema.SchemaType | undefined
      : schema.string();

    if (!schemaObj) {
      continue;
    }

    const handledValue = schemaObj.handle(key, value);

    if (handledValue != null) {
      res[key] = handledValue;
    }
  }

  return res as Partial<T>;
}

export function objectToQueryString(obj: any): string {
  if (!obj) {
    return '';
  }

  return '?' + Object.keys(obj)
    .filter(key => !!obj[key])
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&');
}