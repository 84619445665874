import * as React from 'react';
import * as core from '../../model/core';
import { translate as trans, translateAny as transAny } from '../../lang';
import { getEnumStringValue } from '../../util';
import { FaIcon } from '../../icon';

const createSpec = (list: core.VehicleSpec[]) => {
  return (key: string, func: (spec: core.VehicleSpec) => Partial<SpecItemProps>) => {
    key = key?.toLocaleLowerCase();
    const spec = list.find(s => s.title?.toLocaleLowerCase() === key);

    if (spec) {
      return <SpecItem icon='cog' title={spec.title} value={spec.value} {...func(spec)} />;
    }

    return null;
  };
};

interface SpecificationProps {
  vehicle: core.VehicleExtended;
  showArticleNr: boolean;
}

function CarSpecification({ vehicle, showArticleNr }: SpecificationProps) {
  const getSpec = createSpec(vehicle.specifications);
  const getEssentialSpec = createSpec(vehicle.essentialSpecs);

  return (
    <div className='dso-object-specs-container three-column'>
      <div className='dso-object-specs-column'>
        <h2>{transAny(`object.type.${getEnumStringValue(core.VehicleType, vehicle.type)}`)}</h2>

        <ul className='dso-object-specs-list'>
          {vehicle.year > 0 &&
            <SpecItem
              icon='calendar'
              title={trans('object.specItem.year')}
              value={vehicle.year} />}

          {vehicle.condition > core.Condition.all &&
            <SpecItem
              icon='heartbeat'
              title={trans('object.specItem.condition')}
              value={vehicle.condition === core.Condition.new
                ? trans('object.specItem.condition.new')
                : trans('object.specItem.condition.used')} />}

          {vehicle.regNr &&
            <SpecItem
              icon='tag'
              title={trans('object.specItem.reg')}
              value={vehicle.regNr} />}

          {showArticleNr && vehicle.articleNr && 
            <SpecItem
              icon='tag'
              title={trans('object.specItem.articleNr')}
              value={vehicle.articleNr} />}

          {vehicle.category != null &&
            <SpecItem
              icon='cube'
              title={trans('object.type')}
              value={transAny('filter.cat.' + vehicle.category.toLowerCase())} />}
        </ul>
      </div>

      <div className='dso-object-specs-column'>
        <h2>{trans('object.specTitle.measure')}</h2>

        <ul className='dso-object-specs-list'>
          {getSpec('Tjänstevikt', _ => ({ icon: 'balance-scale', title: trans('object.specItem.workingWeight') }))}
          {getSpec('Totalvikt', _ => ({ icon: 'balance-scale', title: trans('object.specItem.totalWeight') }))}
          {getSpec('Längd', _ => ({ icon: 'arrows-h', title: trans('object.specItem.length') }))}
        </ul>
      </div>

      <div className='dso-object-specs-column'>
        <h2>{trans('object.specTitle.engineinfo')}</h2>

        <ul className='dso-object-specs-list'>
          {/* TODO: fixa felstavning */}
          {getEssentialSpec('Växelåda', spec => ({
            icon: 'code-fork',
            title: trans('object.specItem.gear'),
            value: spec.value === 'Automatic'
              ? trans('object.specItem.gearAuto')
              : trans('object.specItem.gearManual')
          }))}

          {getSpec('Motortyp', _ => ({ icon: 'cogs', title: trans('object.specItem.engineType') }))}

          {vehicle.fuel > 0 &&
            <SpecItem
              icon='tint'
              title={trans('filter.input.fuel')}
              value={transAny('object.specItem.fuel.' + getEnumStringValue(core.VehicleFuel, vehicle.fuel))} />}

          {getEssentialSpec('Effekt', spec => ({
            icon: 'dashboard',
            title: trans('object.specItem.power'),
            value: vehicle.language === 'de'
              ? spec.value.slice(0, -2) + ' kW'
              : spec.value
          }))}

          {vehicle.milage > 0 &&
            <SpecItem
              icon='road'
              title={trans('object.specItem.mileage')}
              value={`${vehicle.milage} ${trans('object.specItem.milageUnit').toLocaleLowerCase()}`} />}
        </ul>
      </div>
    </div>
  );
}

function BilvisionCarSpecification({ vehicle }: SpecificationProps) {
  return (  
    <div>
      <ul className='dso-object-specs-container specs-container-without-headers specs-boxed'>
        {vehicle.specifications
          .filter(spec => spec.category === 'engineinfo')
          .map(spec => <SpecItem key={spec.title} {...spec} />)}
        {vehicle.specifications
          .filter(spec => spec.category === 'general')
          .map(spec => <SpecItem key={spec.title} {...spec} />)}
      </ul>
    </div>
  );
}

export function Specification({ vehicle, showArticleNr }: SpecificationProps) {
  if (!vehicle.specifications) {
    return null;
  }

  if (vehicle.type === core.VehicleType.car) {
    if (vehicle.source === 'bilvision') {
      return <BilvisionCarSpecification vehicle={vehicle} showArticleNr={showArticleNr} />;
    }

    return <CarSpecification vehicle={vehicle} showArticleNr={showArticleNr} />;
  }

  if (vehicle.type >= core.VehicleType.mcAtv && vehicle.type < core.VehicleType.unknown) {
    return (
      <ul className='dso-object-specs-container specs-container-without-headers specs-boxed'>
        {vehicle.specifications.map(spec =>
          <SpecItem key={spec.title} {...spec} />)}
      </ul>
    );
  }

  const getSpec = createSpec(vehicle.specifications);
  const getEssentialSpec = createSpec(vehicle.essentialSpecs);

  return (
    <div className={`dso-object-specs-container ${vehicle.type === core.VehicleType.mobileHome ? 'four-column' : 'three-column'}`}>
      <div className='dso-object-specs-column'>
        <h2>{transAny('object.type.' + getEnumStringValue(core.VehicleType, vehicle.type))}</h2>

        <ul className='dso-object-specs-list'>
          {vehicle.year > 0 &&
            <SpecItem
              icon='calendar'
              title={trans('object.specItem.year')}
              value={vehicle.year} />}

          {vehicle.condition > core.Condition.all &&
            <SpecItem
              icon='heartbeat'
              title={trans('object.specItem.condition')}
              value={vehicle.condition === core.Condition.new
                ? trans('object.specItem.condition.new')
                : trans('object.specItem.condition.used')} />}

          {vehicle.regNr &&
            <SpecItem
              icon='tag'
              title={trans('object.specItem.reg')}
              value={vehicle.regNr} />}

          {showArticleNr && vehicle.articleNr && 
            <SpecItem
              icon='tag'
              title={trans('object.specItem.articleNr')}
              value={vehicle.articleNr} />}

          {(vehicle.type === core.VehicleType.mobileHome && vehicle.milage > 0) &&
            <SpecItem
              icon='road'
              title={trans('object.specItem.mileage')}
              value={`${vehicle.milage} ${trans('object.specItem.milageUnit').toLocaleLowerCase()}`} />}
        </ul>
      </div>

      <div className='dso-object-specs-column'>
        <h2>{trans('object.specTitle.model')}</h2>

        <ul className='dso-object-specs-list'>
          {vehicle.type === core.VehicleType.mobileHome &&
            <SpecItem
              icon='cube'
              title={trans('object.specItem.vehicleType')}
              value={transAny('filter.cat.' + vehicle.category.toLowerCase())} />}

          {((vehicle.type === core.VehicleType.caravan) || (vehicle.type === core.VehicleType.mobileHome)) && vehicle.layout &&
            <SpecItem
              icon='expand-arrows-alt'
              fas={true}
              title={trans('object.specItem.layout')}
              value={transAny('filter.input.layouts.' + vehicle.layout.toLowerCase())} />}

          {(vehicle.type === core.VehicleType.mobileHome) && vehicle.passengers > 0 &&
            <SpecItem
              icon='users'
              title={trans('object.specItem.passengers')}
              value={vehicle.language === 'sv' ? vehicle.passengers + 1 : vehicle.passengers} />}

          {vehicle.type === core.VehicleType.caravan &&
            <SpecItem
              icon='cog'
              title={trans('object.specItem.axleType')}
              value={transAny('filter.cat.' + vehicle.category?.toLowerCase())} />}

          {((vehicle.type === core.VehicleType.caravan) || (vehicle.type === core.VehicleType.mobileHome)) && vehicle.beds > 0 &&
            <SpecItem
              icon='bed'
              title={trans('object.specItem.beds')}
              value={vehicle.beds} />}
        </ul>
      </div>

      <div className='dso-object-specs-column'>
        <h2>{trans('object.specTitle.measure')}</h2>

        <ul className='dso-object-specs-list'>
          {getSpec('Tjänstevikt', _ => ({ icon: 'balance-scale', title: trans('object.specItem.workingWeight') }))}
          {getSpec('Totalvikt', _ => ({ icon: 'balance-scale', title: trans('object.specItem.totalWeight') }))}
          {getSpec('Längd', _ => ({ icon: 'arrows-h', title: trans('object.specItem.length') }))}
          {getSpec('Bredd', _ => ({ icon: 'arrows-h', title: trans('object.specItem.width') }))}
          {getSpec('Höjd', _ => ({ icon: 'arrows-v', title: trans('object.specItem.height') }))}
        </ul>
      </div>

      {(vehicle.type === core.VehicleType.mobileHome) && <>
        <div className='dso-object-specs-column'>
          <h2>{trans('object.specTitle.engineinfo')}</h2>

          <ul className='dso-object-specs-list'>

            {/* TODO: fixa felstavning */}
            {getEssentialSpec('Växelåda', spec => ({
              icon: 'code-fork',
              title: trans('object.specItem.gear'),
              value: spec.value === 'Automatic'
                ? trans('object.specItem.gearAuto')
                : trans('object.specItem.gearManual')
            }))}

            {getSpec('Motortyp', _ => ({ icon: 'cogs', title: trans('object.specItem.engineType') }))}

            {getEssentialSpec('Effekt', spec => ({
              icon: 'dashboard',
              title: trans('object.specItem.power'),
              value: vehicle.language === 'de'
                ? spec.value.slice(0, -2) + ' kW'
                : spec.value
            }))}
          </ul>
        </div>
      </>}
    </div>
  );
}

interface SpecItemProps {
  icon: string;
  fas?: boolean;
  title: string;
  value: any;
}

function SpecItem({ icon, title, value, fas }: SpecItemProps) {
  return (
    <li>
      <FaIcon name={icon} fas={fas} />
      <span className='spec-title'>{title}</span>
      <span className='spec-value'>{value}</span>
    </li>
  );
}
