import * as React from 'react';
import * as core from '../../model/core';
import { VehicleObject } from './vehicleObject';
import { SelectObjectCallback } from './objectFilterView';
import { translate } from '../../lang';
import { FaIcon } from '../../icon';
import { Favourites, vStorageStr, useFavourites } from '../../favourites';
import { ListSchemaProps, VehicleListSchema } from '../../dataSchemas';

interface SearchResultProps {
  objects: core.Vehicle[];
  selectObject: SelectObjectCallback;
  objectLink?: (object: core.Vehicle) => string | undefined | null;
  objectType?: number;
  navigationParams?: Partial<core.ObjectQueryParameters>;
  listView: boolean;
  config: core.GeneralConfig;
  objectTypeStr?: string;
  language?: string;
}

interface SearchResultState {
  objectsToShow: number;
  favouriteStorage: string;
}

export class SearchResult extends React.Component<SearchResultProps, SearchResultState> {

  constructor(props: SearchResultProps) {
    super(props);

    this.state = {
      objectsToShow: 21,
      favouriteStorage: localStorage.getItem(vStorageStr) ?? ''
    };
  }

  showMore = () => {
    if (this.state.objectsToShow + 10 <= this.props.objects.length) {
      this.setState(state => ({ objectsToShow: state.objectsToShow + 9 }));
    } else {
      this.setState({ objectsToShow: this.props.objects.length });
    }
  };

  render() {
    const { objects, selectObject, objectLink } = this.props;

    const setListView = localStorage.getItem('dsoViewToggle') == null
      ? !this.props.listView
      : localStorage.getItem('dsoViewToggle') === 'list';

    if (this.props.language && this.props.objectTypeStr && this.props.objectLink) {
      const listSchema: ListSchemaProps = {
        vehicles: objects,
        language: this.props.language,
        type: this.props.objectTypeStr,
        links: objects.map(obj => objectLink?.(obj)!)
      };
      VehicleListSchema(listSchema);
    }

    return (
      <div className={`dso-article-objects ${!setListView ? 'dso-article-box-view' : '' }`}>
        {objects.length > 0 ? <>
          {objects.map(obj =>
            <VehicleObject
              key={obj.uniqueId}
              object={obj} select={selectObject}
              link={objectLink?.(obj)}
              storageVehicles={this.state.favouriteStorage}
              updateStorage={(str) => this.setState({ favouriteStorage: str })}
              showSuggestedMonthCost={
                this.props.config!.suggestedMonthCost ?? true
              } />).slice(0, this.state.objectsToShow)}

          {objects.length > this.state.objectsToShow && <>
          <div className='dso-show-more-results'
            onClick={this.showMore}><span>{translate('filter.search.showMore')}</span><FaIcon name='arrow-down'/></div>
          </>}

        {useFavourites &&
          <Favourites
            storageVehicles={this.state.favouriteStorage}
            updateStorage={(str) => this.setState({ favouriteStorage: str })}
            config={this.props.config} />}

        </> : <>
          <div className='dso-no-result'>{translate('filter.noResult')}</div>
        </>}

      </div>
    );
  }
}